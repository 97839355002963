<template>
  <MainLayout>
    <div class="container padding-content">
      <div v-if="Object.keys(booking).length" class="content-page box-shadow booking-detail"
           style="border-radius: 10px">
        <h4 class="text-center mb-4">Booking Detail</h4>
        <h5 class="title-section-booking">
          <font-awesome-icon icon="fa-solid fa-spa"/>
          BOOKING OVERVIEW
        </h5>
        <b-row>
          <b-col cols="6">
            <b-row>
              <b-col><p><strong>Booking Ref: </strong></p></b-col>
              <b-col class="color-main">{{ booking.ordercode }}</b-col>
            </b-row>
            <b-row>
              <b-col><p><strong>Booking Date: </strong></p></b-col>
              <b-col>{{ formatDate(booking.created_at, 'DD-MMMM-gggg H:s') }}</b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col><p><strong>Booking Currency: </strong></p></b-col>
              <b-col>{{ booking.namecurrent }}</b-col>
            </b-row>
          </b-col>
        </b-row>
        <h5 class="title-section-booking">
          <font-awesome-icon icon="fa-solid fa-users"/>
          PASSENGER DETAILS
        </h5>
        <div class="list-passengers">
          <div class="passenger-detail" v-for="(passenger, index) in passengers" :key="index">
            <p class="index-passenger">
              <font-awesome-icon icon="fa-solid fa-user"/>
              Passenger {{ index + 1 }} <strong v-if="parseInt(passenger.leader)">(leader)</strong></p>
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col><p><strong>Title: </strong></p></b-col>
                  <b-col>{{ passenger.sur }}</b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>First Name: </strong></p></b-col>
                  <b-col>{{ passenger.name }}</b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Last Name: </strong></p></b-col>
                  <b-col>{{ passenger.lastname }}</b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Birthday: </strong></p></b-col>
                  <b-col>{{ formatDate(passenger.birthday, 'DD-MM-YYYY') }}</b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Nationality: </strong></p></b-col>
                  <b-col>{{ passenger.nationality }}</b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Email: </strong></p></b-col>
                  <b-col>{{ passenger.email }}</b-col>
                </b-row>
                <b-row>
                  <b-col><p class="mb-0"><strong>Phone Number: </strong></p></b-col>
                  <b-col>{{ passenger.countryCodePhone }} {{ passenger.phone }}</b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col><p><strong>Medical/Dietary:</strong></p></b-col>
                  <b-col></b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Bedding Configuration:</strong></p></b-col>
                  <b-col></b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Emergency Phone Number:</strong></p></b-col>
                  <b-col></b-col>
                </b-row>
                <b-row>
                  <b-col><p><strong>Emergency Contact Name: </strong></p></b-col>
                  <b-col></b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
        <h5 class="title-section-booking">
          <font-awesome-icon icon="fa-solid fa-circle-info"/>
          BOOKING DETAILS
        </h5>
        <h6 class="color-main font-bold text-uppercase">{{ cartBooking.name }}</h6>
        <b-row>
          <b-col cols="6">
            <p><strong>Departure
              Date: </strong>{{ formatDate(cartBooking.options.daystart.split("/").reverse().join('-'), 'dddd DD-MMMM-gggg') }}
            </p>
          </b-col>
          <b-col cols="6">
            <p><strong>Trip Code: </strong>{{ cartBooking.options.category }}</p>
          </b-col>
        </b-row>

        <div class="payment-detail">

          <b-row>
            <b-col cols="6">
              <h6>
                <font-awesome-icon icon="fa-solid fa-receipt"/>
                PAYMENT DETAILS <span>({{ cartBooking.payment_due_text }})</span></h6>
              <div class="table-pricing-detail mt-3">
                <table class="table table-bordered">
                  <tbody>
                  <tr v-for="(passenger, index) in passengers" :key="index">
                    <td>{{passenger.sur}} {{passenger.name}} {{passenger.lastname}}</td>
                    <td>{{formatPrice({gross: pricingDetails[index]},  $store.state.currency.name)}}</td>
                  </tr>
                  <tr>
                    <td><strong>Subtotal</strong></td>
                    <td><strong>{{formatPrice({gross: totalPrice}, $store.state.currency.name)}}</strong></td>
                  </tr>
<!--                  <tr>-->
<!--                    <td style="font-style: italic">LESS commission @{{cartBooking.commission_rate}}%</td>-->
<!--                    <td>{{formatPrice({gross: totalPrice * cartBooking.commission_rate / 100}, $store.state.currency.name)}}</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td style="font-size: 20px"><strong>TOTAL</strong></td>-->
<!--                    <td style="font-size: 20px"><strong>{{formatPrice({gross: totalPrice - (totalPrice * cartBooking.commission_rate / 100)}, $store.state.currency.name)}}</strong></td>-->
<!--                  </tr>-->
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col cols="6">
              <h6>
                <font-awesome-icon icon="fa-solid fa-star" />
                PASSENGER PAYMENT DETAILS <span>(Unpaid)</span></h6>
              <table class="table table-bordered mt-3">
                <tbody>
                <tr>
                  <td></td>
                  <td>Amount</td>
                  <td>Due Date</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Total Gross</td>
                  <td>{{formatPrice({gross: totalPrice}, $store.state.currency.name)}}</td>
                  <td>{{ formatDate(cartBooking.payments.first_selected_payment_date.split("/").reverse().join('-'), 'DD-MMMM-gggg') }}</td>
                  <td><b-button class="btn btn-main">Pay Now</b-button></td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import bookingRepository from "@/repositories/booking";
import utils from '../../utils/index';

export default {
  name: "BookingDetailPage",
  components: {MainLayout},
  data() {
    return {
      id: this.$route.params.id,
      booking: {},
      cartBooking: {},
      passengers: {},
      pricingDetails: [],
    }
  },
  computed: {
    totalPrice(){
      let price = 0;
      this.pricingDetails.forEach(item => price+= item);
      return price;
    }
  },
  methods: {
    ...utils
  },
  async created() {
    this.$store.commit('setting/loading', true);
    this.booking = (await bookingRepository.show(this.id)).data.response;
    this.cartBooking = Object.values(JSON.parse(this.booking.cartbooking))[0];
    let passengerFromBooking = Object.values(JSON.parse(this.booking.userlists))[0];
    let passengers = [];
    Object.keys(passengerFromBooking).forEach(key => {
      passengerFromBooking[`${key}`].forEach(passenger => {
        passengers.push(passenger)
      })
    });
    let pricingDetails = [];
    Object.keys(this.cartBooking.pricing_details).forEach(key => {
      this.cartBooking.pricing_details[`${key}`].forEach(pricing => {
        pricingDetails.push(pricing)
      })
    });
    this.pricingDetails = pricingDetails;
    this.passengers = passengers;
    console.log(this.cartBooking);
    this.$store.commit('setting/loading', false);
  }
}
</script>

<style scoped>

</style>