import {deleteFavorite, getFavorites, postAddFavorites} from "@/api/favorite";

const favoriteRepository = {
    index: async () => {
        return await getFavorites();
    },
    add: async productId => {
        return await postAddFavorites({product_id: productId});
    },
    delete: async id => {
        return await deleteFavorite({id: id})
    }
}

export default favoriteRepository;