import {
    getInfo,
    postCheckForgetPasswordToken,
    postForgetPassword,
    postLogin,
    postRegister,
    putUpdatePasswordForget, putUpdateProfile
} from "@/api/user";

const userRepository = {
    register: async data => {
        return await postRegister(data);
    },
    login: async data => {
        return await postLogin(data);
    },
    forgetPassword: async data => {
        return await postForgetPassword(data);
    },
    checkForgetToken: async data => {
        return await postCheckForgetPasswordToken(data);
    },
    updateForgetPassword: async data => {
        return await putUpdatePasswordForget(data);
    },
    updateProfile: async data => {
        return await putUpdateProfile(data);
    },
    show: async () => {
        return await getInfo()
    }
}

export default userRepository;