<template>
  <div>
    <b-modal id="modal-add-favorite" @hidden="resetForm" :hide-footer="true" @show="scrollToTop" :title="product.title"
             no-close-on-backdrop no-close-on-es>
      <ValidationObserver v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitForm)" id="form-add-to-cart">
          <b-row>
            <b-col cols="4">
              <b-form-group
                  label="Departure Date"
                  label-for="departureDate"
              >
                <div class="group-departure-date">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <b-form-datepicker id="example-datepicker" :min="minDate" v-model="form.departureDate"
                                       class="mb-2"></b-form-datepicker>
                    <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>

                </div>
              </b-form-group>
              <b-form-group v-if="form.departureDate" label="Passengers">
                <b-form-input v-model="passengersText" class="bg-transparent" :readonly="true"/>
              </b-form-group>
              <div v-if="form.departureDate" class="pricing-list mt-0">
                <strong class="mb-2 d-block">You can select up to 16 travelers in total</strong>
                <div class="pricing-detail justify-content-between" v-for="passenger in passengerInfo"
                     :key="passenger.id">
                  <p class="pricing-type">
                    {{ passenger.title }} {{ passenger.description }}
                    <span>(Minimum: {{ passenger.min }}, Maximum: {{ passenger.max }})</span>
                  </p>
                  <div class="action">
                    <font-awesome-icon icon="fa-solid fa-minus" @click="changePassengerAmount(passenger.id, 'minus')"/>
                    <span>{{ passenger.amount }}</span>
                    <font-awesome-icon icon="fa-solid fa-plus" @click="changePassengerAmount(passenger.id, 'plus')"/>
                  </div>
                </div>

              </div>
            </b-col>
            <b-col cols="8" v-if="form.departureDate">
              <div class="list-option d-flex flex-column justify-content-between">
                <div class="item-option" v-for="option in optionsBooking" :key="option.id">
                  <b-row>
                    <b-col cols="7">
                      <p class="font-bold">{{ option.title.toUpperCase() }}</p>
                      <div class="line"></div>
                      <p>Select Guide</p>
                      <div class="guide-list">
                        <div class="item-guide" v-for="guide in tourGuideList" :key="guide">

                          <b-form-radio v-if="option.isPrivate" name="some-radios" v-model="form.tourGuidePrivate"
                                        :value="guide">{{ guide }}
                          </b-form-radio>
                          <b-form-radio v-else name="some-radios-group" v-model="form.tourGuideGroup" :value="guide">
                            {{ guide }}
                          </b-form-radio>

                        </div>
                      </div>
                    </b-col>
                    <b-col cols="5" class="d-flex justify-content-between flex-column">
                      <div class="group-pricing" style="text-align: end">
                        <p class="mb-0"><strong>
                          {{
                            formatPrice({
                              gross: passengerInfo.reduce((accumulator, passenger) => {
                                return accumulator + product.price_gross * passenger.amount - (product.price_gross * passenger.amount) * passenger.discount;
                              }, 0)
                            }, $store.state.currency.name, option.isPrivate)
                          }}
                        </strong></p>
                        <p class="pricing-amount mb-0" style="font-size: 14px" v-for="passenger in passengerInfo"
                           :key="passenger.id">
                        <span v-if="passenger.amount">
                          {{ passenger.amount }} {{ passenger.title }} x
                          {{
                            formatPrice({
                                  gross: product.price_gross - (product.price_gross) * passenger.discount,
                                  net: product.price_net - (product.price_net) * passenger.discount,
                                  currency: product.price_currency
                                }, $store.state.currency.name,
                                option.isPrivate)
                          }}
                        </span>
                        </p>
                      </div>
                      <div class="group-button text-right">
                        <b-button class="btn btn-info mr-2" @click="addCart(option.isPrivate)" :disabled="loadingData">
                          <b-spinner v-if="loadingData" small type="grow"></b-spinner>
                          Add To Cart
                          <font-awesome-icon icon="fa-solid fa-shopping-cart"/>
                        </b-button>
                        <b-button class="btn btn-main" :disabled="loadingData"
                                  @click="openModalBookNow(option.isPrivate)">
                          <b-spinner v-if="loadingData" small type="grow"></b-spinner>
                          Book Now
                        </b-button>

                      </div>
                    </b-col>
                  </b-row>
                </div>

              </div>
            </b-col>

          </b-row>

        </b-form>

      </ValidationObserver>
      <template v-if="form.departureDate" #modal-footer>
        <div class="footer-modal">
          <b-button form="form-add-to-cart" class="btn-main" type="submit" :disabled="loadingData">
            <b-spinner v-if="loadingData" small type="grow"></b-spinner>
            Add To Cart
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-success" centered :hide-footer="true" :hide-header-close="true" :hide-header="true">
      <font-awesome-icon icon="fa-solid fa-circle-check"
                         style="margin: 25px  auto 20px auto; font-size: 80px; display: block; color: green"/>
      <p class="text-center mt-2 " style="font-size: 20px">Add To Cart Successfully ! </p>
    </b-modal>
    <modal-book-now :product="this.product" :isPrivate="bookPrivate"
                    :tourGuide="bookPrivate ? form.tourGuidePrivate : form.tourGuidePrivate"
                    :passengerInfo="passengerInfo" :passengers="form.passengers" :departureDate="form.departureDate"/>
  </div>

</template>

<script>
import moment from "moment";
import utils from '@/utils/index';
import {required} from 'vee-validate/dist/rules';
import {extend} from "vee-validate";
import {postAddToCart} from "@/repositories/twtAgentPortal";
import ModalBookNow from "@/components/booking/ModalBookNow";

extend('required', {
  ...required,
  message: 'This filed is required !'
});
export default {
  name: "ModalAddFavorite",
  components: {ModalBookNow},
  props: ['product'],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      optionsBooking: [],
      minDate: new Date(today),
      loadingData: false,
      tourGuideList: ['English', 'Italian', 'Spanish', 'Chinese', 'Japanese', 'French'],
      form: {
        departureDate: null,
        departureTime: null,
        tourGuidePrivate: 'English',
        tourGuideGroup: 'English',
        passengers: {
          adult: 1,
          child: 0,
          infant: 0
        }
      },
      departureDateConfig: {
        customFormatter: function (date) {
          return moment(date).format('YYYY/MM/DD');
        },
        disableDate: {
          to: moment().subtract(1, 'days').toDate(),
          dates: []
        },
      },
      passengerInfo: [
        {
          id: 1,
          key: 'adult',
          title: 'Adult',
          description: '(12-79)',
          min: 1,
          max: 15,
          amount: 1,
          discount: 0
        },
        {
          id: 2,
          key: 'child',
          title: 'Child',
          description: '(2-11)',
          min: 0,
          max: 15,
          amount: 0,
          discount: 0.2
        },
        {
          id: 3,
          key: 'infant',
          title: 'Infant',
          description: '(0-2)',
          min: 0,
          max: 15,
          amount: 0,
          discount: 1
        }
      ],
      passengersText: '1 Adult',
      parentURL: this.$route.query.parent_url,
      bookPrivate: false
    }
  },
  methods: {
    ...utils,
    openModalBookNow: function (isPrivate) {
      this.bookPrivate = isPrivate;
      this.$bvModal.show('modal-booking')

    },
    scrollToTop: function () {
      const iframe = document.getElementById('iframe-agent-portal');
      iframe.contentWindow.postMessage({type: 'scroll-top'}, this.parentURL);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    changePassengerAmount: function (idPassenger, type) {
      let passengerInfoClone = [...this.passengerInfo];
      let readyUpdate = false;
      passengerInfoClone.forEach(passenger => {
        if (passenger.id === idPassenger) {
          if (type === 'minus') {
            if (passenger.amount - 1 < passenger.min) {
              this.$swal({
                type: 'info',
                icon: 'info',
                title: `The number of ${passenger.title} cannot be less than ${passenger.min}`,
                showConfirmButton: true,
              });
            } else {
              readyUpdate = true;
              passenger.amount = passenger.amount - 1;
            }
          } else {
            if (passenger.amount + 1 > passenger.max) {
              this.$swal({
                type: 'info',
                icon: 'info',
                title: `The number of ${passenger.title} cannot be greater  ${passenger.max}`,
                showConfirmButton: true,
              });
            } else {
              readyUpdate = true;
              passenger.amount = passenger.amount + 1;
            }
          }

        }
      });
      let totalAmount = passengerInfoClone.reduce((previousValue, value) => previousValue + value.amount, 0)
      passengerInfoClone.forEach(passenger => {
        passenger.max = 15 - totalAmount + passenger.amount;
      })
      if (readyUpdate) {
        this.passengerInfo = passengerInfoClone;
        this.form = {
          ...this.form, passengers: {
            adult: passengerInfoClone[0].amount,
            child: passengerInfoClone[1].amount,
            infant: passengerInfoClone[2].amount,
          }
        }
        let passengersText = `${passengerInfoClone[0].amount} ${passengerInfoClone[0].amount > 1 ? 'Adults' : 'Adult'}`;
        if (passengerInfoClone[1].amount) passengersText += `, ${passengerInfoClone[1].amount} ${passengerInfoClone[1].amount > 1 ? 'Childs' : 'Child'}`;
        if (passengerInfoClone[2].amount) passengersText += `, ${passengerInfoClone[2].amount} ${passengerInfoClone[2].amount > 1 ? 'Infants' : 'Infant'}`;

        this.passengersText = passengersText;
      }
    },
    addCart: async function (isPrivate) {
      let dataSubmit = {
        user_id: this.$route.query.user_id,
        trip_code: this.$route.params.code,
        departure_date: this.form.departureDate,
        information: {
          departure_time: this.form.departureTime,
          tour_guide: isPrivate ? this.form.tourGuidePrivate : this.form.tourGuideGroup,
          passengers: this.form.passengers,
          currency: this.$route.query.currency,
          private: isPrivate,
          mark_up: isPrivate ? 0.45 : 0.2,
          discount: {
            adult: 0,
            child: 0.2,
            infant: 1
          }
        }
      }
      this.loadingData = true;
      await postAddToCart(dataSubmit);
      this.scrollToTop();
      const iframe = document.getElementById('iframe-agent-portal');
      iframe.contentWindow.postMessage({type: 'increase-cart'}, this.parentURL);
      this.$bvModal.hide('modal-add-favorite')
      this.$bvModal.show('modal-success')
      let that = this;
      setTimeout(function () {
        that.$bvModal.hide('modal-success')

      }, 2000)
      this.loadingData = false;
      // this.$swal({
      //   type: 'success',
      //   icon: 'success',
      //   position: 'top-center',
      //   title: `Add To Cart Successfully !`,
      //   showConfirmButton: false,
      //   timer: 3000,
      //   showDenyButton: false,
      //   showCancelButton: false
      // });
    },
    changeDepartureDate: function (e) {
      this.form = {...this.form, departureDate: moment(e).format('YYYY-MM-DD')}
    },
    resetForm: function () {
      this.passengerInfo = [
        {
          id: 1,
          title: 'Adult',
          description: '(12-79)',
          min: 1,
          max: 15,
          amount: 1,
          discount: 0
        },
        {
          id: 2,
          title: 'Child',
          description: '(2-11)',
          min: 0,
          max: 15,
          amount: 0,
          discount: 0.2
        },
        {
          id: 3,
          title: 'Infant',
          description: '(0-2)',
          min: 0,
          max: 15,
          amount: 0,
          discount: 1
        }
      ];
      this.form = {
        departureDate: null,
        departureTime: null,
        tourGuidePrivate: 'English',
        tourGuideGroup: 'English',
        passengers: {
          adult: 1,
          child: 0,
          infant: 0
        }
      };
    }

  },
  watch: {
    form: function (newVal, oldVal) {
      console.log(newVal, oldVal);
    }
  },
  created() {
    console.log(this.product?.attributes);
    this.optionsBooking = (this.product?.attributes ?? []).filter(item => item.id == '0797dc52-9529-11ea-b3d6-06b40dd1ea60').length ? [
      {
        id: 1,
        title: 'private',
        isPrivate: true,
      }
    ] : [
      {
        id: 1,
        title: 'private',
        isPrivate: true,
      }, {
        id: 2,
        title: 'join group',
        isPrivate: false,
      }
    ]
  }

}
</script>

<style scoped>

</style>