import xhr from "@/api/config/xhr";
import endPoint from "@/api/config/endPoint";
import {getURL} from "@/api/config/endPoint";

export const getCurrencyExchangeRate =  async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.TWT_CURRENCY_EXCHANGE_RATE, routeParams, queryParam));
}

export const addToCart = async (data) => {
    return await xhr.post(getURL(endPoint.TWT_ADD_TO_CART), data);
}
