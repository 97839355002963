<template>
  <MainLayout>
    <div class="container padding-content">
      <div class="content-page box-shadow booking-list" style="border-radius: 10px">
        <h5 class="text-center mb-4">Booking List</h5>
        <div v-for="booking in bookingListAfterFormat" class="booking-index-item" :key="booking.id">
          <b-row>
            <b-col cols="3">
              <b-img style="height: 100%" :src="booking.cartBooking.options.preview_image" thumbnail></b-img>
            </b-col>
            <b-col cols="7">
              <h4 class="mb-2">{{ booking.cartBooking.name }}</h4>
              <p class="mb-1"><strong>Created: </strong>{{ formatDate(booking.created_at, 'DD-MMMM-gggg') }}</p>
              <p class="mb-1"><strong>Departure Date: </strong> {{ formatDate(booking.departureDate, 'dddd DD-MMMM-gggg') }}</p>
              <p class="mb-1"><strong>Guest: </strong> <span style="font-style: italic; font-size: 90%"  v-for="(passenger, index) in booking.passengers" :key="passenger">{{ index ?  `, ${passenger}` : passenger}} </span></p>
              <b-row>
                <b-col cols="6">
                  <p class="mb-1"><strong>Payment Status: </strong> <span>{{booking.cartBooking.payment_due_text}}</span> </p>
                </b-col>
                <b-col>
                  <p class="mb-0 total-price"><strong>Total Price: </strong> <span>{{formatPrice({gross: booking.cartBooking.payments.total_gross},  $store.state.currency.name)}}</span> </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="2">
              <b-button @click="gotoDetailPage(booking.id)" class="btn btn-info">View Detail</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import bookingRepository from "@/repositories/booking";
import utils from '../../utils/index';
import UrlPattern from "url-pattern";
import {routeWebList} from "@/routes";

export default {
  name: "BookingListPage",
  components: {MainLayout},
  data() {
    return {
      bookings: []
    }
  },
  methods: {
    ...utils,
    gotoDetailPage: function (id){
      let pattern = new UrlPattern(routeWebList.BOOKING_DETAIL);
      pattern = pattern.stringify({ id: id});
      this.$router.push(pattern);
    }
  },
  computed: {
    bookingListAfterFormat() {
      return this.bookings.map(item => {
        let cartBooking = Object.values(JSON.parse(item.cartbooking))[0];
        let departureDate = cartBooking.options.daystart.split("/").reverse().join('-');
        let passengerFromBooking = Object.values(JSON.parse(item.userlists))[0];
        let passengers = [];
        Object.keys(passengerFromBooking).forEach(key => {
          passengerFromBooking[`${key}`].forEach(passenger => {
            passengers.push(`${passenger.sur} ${passenger.name} ${passenger.lastname}`)
          })
        })
        return {
          id: item.id,
          productCode: item.category,
          orderCode: item.ordercode,
          cartBooking: cartBooking,
          createdAt: item.created_at,
          departureDate: departureDate,
          passengers: passengers,
          created_at: item.created_at
        }
      })
    }
  },
  created() {
    this.$store.commit('setting/loading', true)
    bookingRepository.index().then(res => {
      this.bookings = res.data.response;
      this.$store.commit('setting/loading', false)
    })
  }
}
</script>

<style scoped>

</style>