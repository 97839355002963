<template>
  <main>
    <!-- <header-main></header-main> -->
    <b-sidebar
        id="sidebar-menu"
        backdrop-variant="white"
        backdrop
        shadow
    >
      <div class="px-3 py-2">
        <tree-item-sidebar :item="categories" type="category" class="root-tree-item"></tree-item-sidebar>
        <tree-item-sidebar :item="attributes" type="attribute" class="root-tree-item"></tree-item-sidebar>
        <tree-item-sidebar :item="countries" type="place" class="root-tree-item"></tree-item-sidebar>
      </div>
    </b-sidebar>
    <slot></slot>
    <!-- <footer-component></footer-component> -->
    <!-- <modal-login></modal-login>
    <modal-register></modal-register>
    <modal-forget-password></modal-forget-password> -->
    <div class="text-center back-drop" v-if="$store.state.setting.loading"></div>
    <div class="spin-group " v-if="$store.state.setting.loading">
      <b-spinner type="grow" label="Spinning"></b-spinner>
      <b-spinner type="grow" label="Spinning"></b-spinner>
      <b-spinner type="grow" label="Spinning"></b-spinner>
    </div>
  </main>
</template>

<script>
// import HeaderMain from "@/components/header/HeaderMain";
// import FooterComponent from "@/components/footer/FooterComponent";
import {getCurrencyRate} from "@/repositories/twtAgentPortal";
import productRepository from "@/repositories/product";
import TreeItemSidebar from "@/components/treeview/TreeItemSidebar";
import xhr from "@/api/config/xhr";
// import ModalLogin from "@/components/auth/ModalLogin";
// import ModalRegister from "@/components/auth/ModalRegister";
// import ModalForgetPassword from "@/components/auth/ModalForgetPassword";
import favoriteRepository from "@/repositories/favorite";
import userRepository from "@/repositories/user";

export default {
  name: "MainLayout",
  components: {TreeItemSidebar},
  // components: {ModalForgetPassword, ModalRegister, ModalLogin, TreeItemSidebar, FooterComponent, HeaderMain},
  data(){
    return{
      categories: {name: "Categories", children: []},
      attributes: {name: "Advanced Filters", children: []},
      countries: {name: "Places", children: []},
    }
  },
  async created() {
    window.scrollTo(0, 0);
    if(localStorage.getItem('static_data') !== null){
      this.$store.commit('setting/setSetting',  JSON.parse(localStorage.getItem('static_data')));
    }
    this.$store.commit('currency/change', {name: this.$route.query.currency});
    xhr.get(`${process.env.VUE_APP_API_URL}page-content`).then(res => {
      this.$store.commit('setting/setSetting', res.data.response);
      localStorage.setItem('static_data' , JSON.stringify(res.data.response));
    });
    if (localStorage.getItem('sidebarData') === null){
      productRepository.filterList().then(res => {
        this.categories = {...this.categories, children: res.categories};
        this.attributes = {...this.attributes, children: res.attributes};
        this.countries = {...this.countries, children: res.countries};
        localStorage.setItem('sidebarData', JSON.stringify(res));
      });
    } else{
      let sidebarData = JSON.parse(localStorage.getItem('sidebarData'));
      this.categories = {...this.categories, children: sidebarData.categories};
      this.attributes = {...this.attributes, children: sidebarData.attributes};
      this.countries = {...this.countries, children: sidebarData.countries};
    }
    if (this.$store.state.auth.access_token !== null){
      favoriteRepository.index().then(res => {
        this.$store.commit('auth/setFavorite', res.data.response);
      })
    }
    if (this.$store.state.auth.access_token  !== null){
      let user =  await userRepository.show()
      this.$store.commit('auth/update', {
        access_token: this.$store.state.auth.access_token,
        user: user.data.response
      })
    }
    await getCurrencyRate();

  }
}
</script>

<style scoped>

</style>