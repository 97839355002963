<template>
  <div class="favorite-item item-product">
    <b-card>
      <div class="image-product">
        <img :src="product.thumbnail_url" alt="">
      </div>
      <div class="content">
        <p class="country">
          <font-awesome-icon icon="fa-regular fa-map"/>
          {{ product.city.name ?? '' }} -
          {{ product.country.name == 'United States of America' ? 'USA' : product.country.name }}
        </p>
        <h5 class="title">{{ product.title }}</h5>
        <div class="description">{{ product.summary }}</div>
        <span><font-awesome-icon icon="fa-regular fa-clock"/> {{ makeDurationString(product.duration) }}</span>
        <p class="price">from : <span>{{
            formatPrice({
              gross: product.price.gross,
              net: product.price.net,
              currency: product.price.currency
            }, $store.state.currency.name)
          }}</span></p>
        <div class="footer-favorite">
          <b-btn @click="gotoProductDetail(product)" class="btn-main">Book Now</b-btn>
          <font-awesome-icon @click="removeFavorite" class="delete-favorite"  icon="fa-solid fa-heart" />
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
import utils from '@/utils/index';
import UrlPattern from "url-pattern";
import {routeWebList} from "@/routes";
import favoriteRepository from "@/repositories/favorite";

export default {
  name: "FavoriteItem",
  props: ['favorite', 'triggerLoading'],
  data() {
    return {
      product: this.favorite.product,
      loadingAll: false
    }
  },
  methods: {
    ...utils,
    gotoProductDetail: function (product) {
      let pattern = new UrlPattern(routeWebList.PRODUCT_DETAIL);
      pattern = pattern.stringify({slug: product.slug, code: product.code});
      this.$router.push(pattern);
    },
    removeFavorite: function (){
      this.$store.commit('setting/loading', true)
      favoriteRepository.delete(this.favorite.id).then((res) => {
        this.$store.commit('setting/loading', false)
        this.$store.commit('auth/setFavorite', res.data.response);
        this.$swal({
          type: 'success',
          icon: 'success',
          title: 'Product removed from favorites',
          timer: 1500,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
      })
    }
  }
}
</script>

<style scoped>

</style>