import {getCountryByIp} from "@/api/country";
import axios from "axios";

const countryRepository = {
    cities: async () => {
        let infoIp = await axios.get('https://api.ipify.org/?format=json');
        let response = await getCountryByIp({}, {ip: infoIp.data.ip});
        return response.data.response;
    }

}

export default countryRepository;
