<template>
  <main-layout>
    <section id="banner">
      <img v-if="!($store.state.setting.page_content?.banner?.banner_image ?? false)" src="@/assets/images/banner.png" alt="" style="height: 65vh;max-height: 465px;">
      <img v-else :src="`${backendURL}${$store.state.setting.page_content.banner.banner_image}`">
      <div class="content">
        <b-container >
          <h3>{{ $store.state.setting.page_content?.banner?.title ?? 'Discover' }}</h3>
          <p>{{ $store.state.setting.page_content?.banner?.description ?? 'Amazing activities & sightseeings around you' }}</p>
          <div class="search">
            <div>
              <b-row class="justify-content-center">
                <b-col cols="8">
                  <div class="custom-input-search location">
                    <b-form-group
                        label="You next destinations or activities"
                    >
                      <b-form-input @keyup.enter="actionSearch"  v-model="querySearch" placeholder="Sydney, London, Paris, Hiking, Museum..."></b-form-input>
                    </b-form-group>
                    <img src="@/assets/images/location.png" alt="">

                  </div>
                </b-col>
                <!--                <b-col cols="4">-->
                <!--                  <div class="custom-input-search date">-->
                <!--                    <b-form-group-->
                <!--                        label="Dates"-->
                <!--                    >-->
                <!--                      <datepicker :disabled-dates="dateSearchConfig.disableDate"-->
                <!--                                  :value="dateSearchConfig.defaultValue"></datepicker>-->
                <!--                    </b-form-group>-->
                <!--                    <img src="@/assets/images/calendar.png" alt="">-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <b-col cols="2">
                  <b-btn @click="actionSearch" class="btn-main">Search <img src="@/assets/images/search.png" alt="">
                  </b-btn>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>

      </div>
    </section>

    <div class="container padding-content product-detail" v-if="Object.keys(product).length">
      <h3 id="product-name">{{ product.title }}</h3>
      <b-row>
        <b-col cols="8">
          <section id="image-gallery">
            <vue-slick-carousel v-if="product.images.length" v-bind="configSlideImage" class="arrow-in-section">
              <div v-for="image in product.images" :key="image" class="item-image">
                <img :src="image" alt="">
              </div>
            </vue-slick-carousel>
          </section>
        </b-col>
        <b-col cols="4">
          <section id="price-box">
            <span>Best Price Guarantee</span>
            <span v-if="product.provider && $route.query.is_admin" class="operator">Operator: {{ product.provider.name }}</span>
            <div class="row-info price">
              <span class="type">From</span>
              <p class="info">{{
                  formatPrice({
                    gross: product.price_gross,
                    net: product.price_net,
                    currency: product.price_currency
                  }, $store.state.currency.name,
                      product.attributes.filter(item => item.id == '0797dc52-9529-11ea-b3d6-06b40dd1ea60').length)
                }}</p>
            </div>
            <div class="row-info" v-if="product.duration && makeDurationString(product.duration)">
              <span><font-awesome-icon icon="fa-regular fa-clock"/></span>
              <p class="info">{{ makeDurationString(product.duration) }}</p>
            </div>
            <div class="row-info" v-if="product.is_free_cancel">
              <span><font-awesome-icon icon="fa-solid fa-ban"/></span>
              <p class="info">Free Cancellation</p>
            </div>
            <div class="row-info" v-if="product.country">
              <span><font-awesome-icon icon="fa-solid fa-map-pin"/></span>
              <p class="info"> {{ product.city.name ?? '' }} -
                {{ product.country.name == 'United States of America' ? 'USA' : product.country.name }}</p>
            </div>
            <div class="row-info instant">
              <span><font-awesome-icon icon="fa-solid fa-bolt-lightning"/></span>
              <p>Instant Confirmation</p>
            </div>
<!--            <b-button class="btn-main btn-booking" v-b-modal.modal-booking>Book Now</b-button>-->

             <b-button class="get-reward btn-transparent" v-b-modal.modal-add-favorite >Book Now<font-awesome-icon icon="fa-solid fa-shopping-cart" /> </b-button>
          </section>
        </b-col>
      </b-row>
      <section id="info-product">
        <div class="overview">
          <b-row>
            <b-col cols="8">
              <h4 class="title">Overview</h4>
              <p v-html="product.summary" style="white-space: pre-line"></p>
              <div class="list-collapse-content" v-if="Object.keys(product.information)">
                <div class="item-collapse" v-for="keyInformation in Object.keys(product.information)"
                     :key="keyInformation">
                  <p class="title" v-b-toggle="keyInformation"> {{ keyInformation.replaceAll('_', ' ') }}</p>
                  <b-collapse visible :id="keyInformation">
                    <div v-for="itemInformation in product.information[keyInformation]" :key="itemInformation.id">
                      <p v-if="itemInformation.name != null">
                        <font-awesome-icon :icon="['fas', 'check']"/>
                        {{ itemInformation.name }}
                      </p>
                      <p v-if="itemInformation.description != null">
                        <font-awesome-icon :icon="['fas', 'check']"/>
                        {{ itemInformation.description }}
                      </p>
                    </div>
                  </b-collapse>
                </div>
              </div>

            </b-col>
            <b-col cols="4" v-if="product.attributes.length">
              <div class="content-list  bg-white">
                <div class="content-list-item" v-for="attribute in product.attributes" :key="attribute.id">
                  <div class="icon">
                    <font-awesome-icon :icon="renderIconAttrProduct(attribute.level1)"/>
                  </div>
                  <div class="content">
                    <span class="title d-block">{{ attribute.level1 }}</span>
                    <span class="content d-block">{{ attribute.name }}</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

        </div>
      </section>
      <section id="might-like" class="mt-5">
        <p class="title-wrap">Recommended activities <span>for you </span></p>
        <vue-slick-carousel v-if="recommendProduct.length" class="big-arrow" :arrows="true"
                            v-bind="slickRecommendConfig">
          <div v-for="product in recommendProduct" :key="product.code">
            <product-component :product="product"></product-component>
          </div>
        </vue-slick-carousel>
        <div class="text-center" v-else>
          <!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
          <!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
          <!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
        </div>
      </section>
    </div>
    <div class="container padding-content" v-else>
      <div class="text-center">
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <b-spinner type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
<!--    <modal-booking :product="product"/>-->
    <modal-add-favorite :product="product" />
    <div class="d-none">
      <iframe id="iframe-agent-portal" src="" />
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import ProductComponent from "@/components/product/ProductComponent";
// import {findByCode, getProductRecommend} from "@/repositories/product";
import productRepository from "@/repositories/product";
import utils from '@/utils/index';
// import ModalBooking from "@/components/booking/ModalBooking";
import favoriteRepository from "@/repositories/favorite";
import UrlPattern from "url-pattern";
import {routeWebList} from "@/routes";
import ModalAddFavorite from "@/components/booking/ModalAddFavorite";

export default {
  name: "ProductDetailPage",
  components: {ModalAddFavorite, MainLayout, VueSlickCarousel, ProductComponent},
  data: function () {
    return {
      slickRecommendConfig: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1
      },
      querySearch: '',
      configSlideImage: {
        "dots": false,
        "fade": true,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "arrows": true,
        // "autoplay": true,
        // "autoplaySpeed": 2000,
      },
      product: {},
      recommendProduct: [],
      backendURL: process.env.VUE_APP_BACKEND_URL,
      parentURL: this.$route.query.parent_url

    }
  },
  methods: {

    ...utils,
    addToFavorites: function () {
      if (this.$store.state.auth.access_token === null) {
        this.$bvModal.show('login-modal');
      } else {
        this.$store.commit('setting/loading', true)
        favoriteRepository.add(this.product.id).then((res) => {
          this.$store.commit('setting/loading', false)
          this.$store.commit('auth/setFavorite', res.data.response);
          this.$swal({
            type: 'success',
            icon: 'success',
            title: 'Successfully added to favorites',
            timer: 1500,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }).catch(() => {
          this.$store.commit('setting/loading', false)
          this.$swal({
            type: 'success',
            icon: 'success',
            title: 'Failed to add to favorites',
            timer: 1500,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });
      }
    },
    actionSearch: function (){
      if (this.querySearch){
        let currentQuery = this.$route.query
        let pattern = new UrlPattern(routeWebList.PRODUCTS);
        const result = '?' + new URLSearchParams({...currentQuery, ...{t: new Date().getTime()}, q: this.querySearch}).toString();
        pattern = pattern.stringify() + result;
        this.$router.push(pattern);
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
    if (localStorage.getItem(`product_detail_${this.$route.params.code}`) !== null) {
      this.product = JSON.parse(localStorage.getItem(`product_detail_${this.$route.params.code}`));
    }
    productRepository.findByCode(this.$route.params.code).then(res => {
      this.product = res;
      localStorage.setItem(`product_detail_${this.$route.params.code}`, JSON.stringify(res));
    });
    productRepository.recommend().then(res => this.recommendProduct = res);
  },
  beforeRouteUpdate(to, from, next) {
    this.product = {};
    this.recommendProduct = [];
    console.log(from);
    window.scrollTo(0, 0);
    if (localStorage.getItem(`product_detail_${to.params.code}`) !== null) {
      this.product = JSON.parse(localStorage.getItem(`product_detail_${to.params.code}`));
    }
    productRepository.findByCode(to.params.code).then(res => {
      this.product = res;
      localStorage.setItem(`product_detail_${to.params.code}`, JSON.stringify(res));
    });
    productRepository.recommend().then(res => this.recommendProduct = res);
    next();
  },
  mounted() {
    const thatParentURL = this.parentURL;
    document.getElementById('iframe-agent-portal').setAttribute('src', thatParentURL);
    document.getElementById('iframe-agent-portal').onload = function (){
      const iframe = document.getElementById('iframe-agent-portal');
      iframe.contentWindow.postMessage({ type: 'set-height' , height: document.querySelector('main').offsetHeight},thatParentURL);
    }
    const resize_ob = new ResizeObserver(function(entries) {
      let rect = entries[0].contentRect;
      const iframe = document.getElementById('iframe-agent-portal');
      iframe.contentWindow.postMessage({ type: 'set-height' , height: rect.height},thatParentURL);
    });
    resize_ob.observe(document.querySelector("main"));
  }
}
</script>

<style scoped>

</style>