import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Fragment from 'vue-fragment'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/scss/app.scss'
import '../src/assets/scss/responsive.scss'
import 'vue-select/dist/vue-select.css';
import '../src/utils/registerIcon'
import router from '../src/routes/index';
import store from "@/stores";


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Fragment.Plugin)
Vue.use(VueSweetalert2);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('v-select', vSelect)
Vue.prototype.$log = console.log
new Vue({
  render: h => h(App),
  store,
  router

}).$mount('#app')
