const auth = {
    namespaced: true,
    state: {
        access_token: localStorage.getItem('access_token'),
        user: localStorage.getItem('user')  !== null ? JSON.parse(localStorage.getItem('user')) : null,
        favorites: [],
    },
    mutations: {
        update: (state, payload) => {
            state.access_token = payload.access_token;
            state.user = payload.user;
        },
        setFavorite: (state, payload) => {
            state.favorites = payload;
        }
    }
}

export default auth;