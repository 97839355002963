import Vuex from 'vuex'
import currency from './modules/currency';
import language from "./modules/language";
import setting from "./modules/setting";
import auth from "./modules/auth";
import Vue from "vue";
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        currency: currency,
        language: language,
        setting: setting,
        auth: auth
    }
});

export default store;