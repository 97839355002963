<template>
  <MainLayout>
    <div class="container padding-content ">
      <b-row>
        <b-col  v-for="favorite in $store.state.auth.favorites" :key="favorite.product.id" cols="4">
          <favorite-item :favorite="favorite"  ></favorite-item>
        </b-col>
      </b-row>
    </div>
    <div class="text-center loading-all" v-if="loadingAll">
      <b-spinner type="grow" label="Spinning"></b-spinner>
      <b-spinner type="grow" label="Spinning"></b-spinner>
      <b-spinner type="grow" label="Spinning"></b-spinner>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import FavoriteItem from "@/components/favorite/FavoriteItem";
export default {
  name: "FavoritePage",
  components: {FavoriteItem, MainLayout},
  data(){
    return{
      loadingAll: false
    }
  },
  created() {
    if (this.$store.state.auth.access_token === null){
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>

</style>