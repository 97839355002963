import {bookingConvertQuoteToBooking, bookingCreateQuote, findById, getBookingList} from "@/api/booking";

const bookingRepository = {
    createQuote: async (data) => {
        return await bookingCreateQuote(data);
    },
    convertQuoteToBooking: async (data) => {
        return await bookingConvertQuoteToBooking(data);
    },
    index: async () => {
        return await getBookingList();
    },
    show: async id => {
        return await findById(id);
    }
}

export default bookingRepository;