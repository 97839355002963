import VueRouter from 'vue-router'
import Vue from "vue";
import HomePage from "@/pages/homepage/HomePage";
import SubscribePage from "@/pages/subscribe/SubscribePage";
import ProductDetailPage from "@/pages/product_detail/ProductDetailPage";
import ProductsPage from "@/pages/products/ProductsPage";
import FavoritePage from "@/pages/favorite/FavoritePage";
import ProfilePage from "@/pages/profile/ProfilePage";
import BookingListPage from "@/pages/booking/BookingListPage";
import BookingDetailPage from "@/pages/booking/BookingDetailPage";
Vue.use(VueRouter)

export const routeWebList = {
    "PRODUCT_DETAIL": '/product/:slug/:code',
    "PRODUCTS": '/products',
    "PRODUCTS_BY_TYPE": '/products/:type/:id',
    "BOOKING_DETAIL": '/booking/:id'
}

const routes = [
    {path: '/', component: HomePage},
    {path: '/subscribe', component: SubscribePage},
    {path: '/product/:slug/:code', component: ProductDetailPage, name: 'product_detail'},
    {path: '/products', component: ProductsPage, name: 'products'},
    {path: '/products/:type/:id', component: ProductsPage, name: 'products_by_type'},
    {path: '/favorites', component: FavoritePage, name: 'favorites'},
    {path: '/profile', component: ProfilePage, name: 'profile'},
    {path: '/bookings', component: BookingListPage, name: 'booking_list'},
    {path: '/booking/:id', component: BookingDetailPage, name: 'booking_detail'}
];

const router = new VueRouter({
    mode: 'history',
    routes
});
export default router;