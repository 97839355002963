<template>
  <b-modal id="modal-booking" @hidden="resetModalBooking" size="xl" :title="product.title" :hide-footer="step === 1 || step === 4 "
           no-close-on-backdrop no-close-on-esc >
    <template #modal-title>
      <ol class="steps mb-0 w-100 hide-step-bar-twt">
        <li class="title_step title_step_1 " :class="{ current: step === 1 }"><a href="#" title=""> Passenger
          Details</a></li>
        <li class="title_step title_step_2 " :class="{ current: step === 2 }"><a href="#" title=""> Booking Summary</a>
        </li>
        <li class="title_step title_step_3 " :class="{ current: step === 3 }"><a href="#" title="">Payment Options </a>
        </li>
        <li :class="{ current: step === 4 }"><a href="#" title=""> Submit </a></li>
      </ol>
    </template>
    <div class="list-step">
      <section id="step-1" :class="{'d-none' : step !== 1}">
        <h4>Passengers</h4>
        <ValidationObserver v-slot="{handleSubmit}">
          <form @submit.prevent="handleSubmit(submitPassengers)" autocomplete="off" >
            <div v-for="(value, name, index) in passengers" :key="index">
              <div class="box-passenger-type" v-if="value">
                <table class="table table-bordered table-hover ">
                  <thead>
                  <tr>
                    <td>{{ capitalizeFirstLetter(name) }}</td>
                    <td colspan="4"></td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="amount in value" :key="`${name}-${amount}`" :name="name" >
                    <td style="width: 170px">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-form-select
                            v-if="name == 'adult'"
                            v-model="dataPassengers.adult[amount - 1].title"
                            :options="titleOptions.adult"
                            value-field="value"
                            text-field="text"
                        ></b-form-select>
                        <b-form-select
                            v-else-if="name == 'child'"
                            v-model="dataPassengers.child[amount - 1].title"
                            :options="titleOptions.child"
                            value-field="value"
                            text-field="text"
                        ></b-form-select>
                        <b-form-select
                            v-else
                            v-model="dataPassengers.infant[amount - 1].title"
                            :options="titleOptions.infant"
                            value-field="value"
                            text-field="text"
                        ></b-form-select>
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <input type="text" name="firstname[]" placeholder="First Name*" v-model="dataPassengers[`${name}`][amount - 1].firstName"  class="form-control">
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider  rules="required" v-slot="{errors}">
                        <input type="text" name="lastname[]" placeholder="Last Name*" v-model="dataPassengers[`${name}`][amount - 1].lastName"  class="form-control">
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                    <td style="width: 250px">
                      <ValidationProvider rules="required" v-slot="{errors}">

                      <input type="date" name="lastname[]" placeholder="Date Of Birth* - DD/MM/YYYY" v-model="dataPassengers[`${name}`][amount - 1].birthday"
                             class="form-control">
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                    <td style="width: 300px;">
                      <ValidationProvider rules="required" v-slot="{errors}">

                      <v-select autocomplte="off" :options="countries.map(item => `${item.label}`)" v-model="dataPassengers[`${name}`][amount - 1].nationality"
                                placeholder="Country of Passport*"></v-select>
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="step == 1" class="footer-modal footer-step-2 d-flex justify-content-end">
              <b-button class="btn-main" type="submit"     :disabled="loadingData"> <b-spinner v-if="loadingData" small type="grow"></b-spinner> Next</b-button>
            </div>
          </form>
        </ValidationObserver>
      </section>
      <section id="step-2" :class="{'d-none' : step !== 2}">
        <div class="row-info">
          <p class="info"><span style="font-weight: bold">Product Name:</span> <span>{{ product.title }}</span></p>
        </div>
        <div class="row-info">
          <p class="info"><span style="font-weight: bold">Departure Date:</span> <span>{{ formatDate(departureDate, 'dddd DD-MMMM-gggg') }}</span></p>
        </div>
        <div class="row-info">
          <p class="info"><span style="font-weight: bold">Tour Guide:</span> <span>{{ tourGuide }}</span></p>
        </div>
        <b-row>
          <b-col cols="3">
            <b-img :src="product.thumbnail_url" thumbnail></b-img>
          </b-col>
          <b-col cols="5">
            <table class="b-table table w-100 table-price mb-0">
              <thead>
              <tr>
                <th scope="col">Quantity</th>
                <th scope="col">Price ({{ $store.state.currency.name }})</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="passenger in passengerInfo.filter(item => item.amount )" :key="passenger.id">
                <td> <b> <i>{{capitalizeFirstLetter(passenger.title)}}: {{passenger.amount}} x {{formatPrice({
                      gross: product.price_gross - (product.price_gross) * passenger.discount,
                      net: product.price_net - (product.price_net) * passenger.discount,
                      currency: product.price_currency
                    }, $store.state.currency.name,
                    isPrivate)}}</i> </b></td>
                <td>
                  {{formatPrice({
                  gross: (product.price_gross * passenger.amount) - (product.price_gross * passenger.amount) * passenger.discount,
                  net: product.price_net * passenger.amount - (product.price_net * passenger.amount) * passenger.discount,
                  currency: product.price_currency
                  }, $store.state.currency.name,
                  isPrivate)}}
                </td>
              </tr>
              <tr>
                <td>
                  <b><i>Total</i></b>
                </td>
                <td>
                  <b>
                    {{
                      formatPrice({
                        gross: passengerInfo.reduce((accumulator, passenger) => {
                          return accumulator + product.price_gross * passenger.amount - (product.price_gross * passenger.amount) * passenger.discount;
                        }, 0)
                      }, $store.state.currency.name, isPrivate)
                    }}

                  </b>

                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="4">
            <b-form-textarea
                v-model="noteClient"
                id="textarea"
                placeholder="Note from Client / Agent"
                rows="5"
            ></b-form-textarea>
            <b-form-checkbox
                id="checkbox-term"
                :value="true"
                :unchecked-value="false"
                :class="'mt-2'"
                v-model="acceptTerm"
            >
              I accept the <a href="https://agentportal.tweetworldtravel.com/terms-conditions" target="_blank"
                              style="color: rgb(0, 123, 255) !important;">Terms and Conditions*</a>
            </b-form-checkbox>
          </b-col>
        </b-row>

      </section>
      <section id="step-4" :class="{'d-none': step !== 3}">
        <p>Please select the payment option/amount below: </p>
        <h5 class="mb-4">SELECT YOUR PAYMENT METHOD</h5>
        <b-tabs pills card vertical v-model="tabPaymentIndex" class="tab-payment-method">
          <b-tab title="Electronic Fund Transfer (EFT)" active class="tab-eft">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="EFTMethod" :aria-describedby="ariaDescribedby" name="some-radios" value="M">Mint
                EFT
              </b-form-radio>
              <b-form-radio v-model="EFTMethod" :aria-describedby="ariaDescribedby" name="some-radios" value="TP">Travel
                Pay EFT
              </b-form-radio>
            </b-form-group>
            <p>Supplier Code: {{ EFTMethod === 'M' ? 'M309594' : 'TPStweet' }} </p>
          </b-tab>
          <b-tab title="Direct Credit">
            <p>Please make payments to the below WESTPAC Bank Account</p>
            <div class="row-info">
              <p class="info">Account Name: <span>Tweet World Travel Tour Operator & Wholesaler Pty. Ltd</span></p>
            </div>
            <div class="row-info">
              <p class="info">BSB: <span>035-052</span></p>
            </div>
            <div class="row-info">
              <p class="info">Account No: <span>384069</span></p>
            </div>
            <div class="row-info">
              <p class="info">International Bank Transfer SWIFT Code: <span>WPACAU2S</span></p>
            </div>
            <p>Please advise payment to: contact@tweetworldtravel.com.au</p>
          </b-tab>
          <b-tab title="Pay by PayPal">
            <b-card-text>All transactions are secure and encrypted with PayPal Checkout</b-card-text>
          </b-tab>
          <b-tab title="With Credit Card">
            <p>We Accept</p>
            <img src="@/assets/images/payment-method.png" alt="">
            <p class="mt-4 mb-0">All transaction are secure and encrypted</p>
          </b-tab>
        </b-tabs>
        <h5 class="mt-4">SELECT YOUR PAYMENT TYPE</h5>
        <b-row>
          <b-col cols="6">
            <div class="payment-table">
              <div class="border-payment-type">
                <div class="total-price">
                  $ {{ `${dataBooking?.payment_detail?.total_gross} ${$store.state.currency.name}` }} gross due
                </div>
                <table class="b-table table table-bordered w-100 mb-0">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Price</th>
                    <th>Due Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>Price</th>
                    <td>$ {{ `${dataBooking?.payment_detail?.total_gross} ${$store.state.currency.name}` }}</td>
                    <td>{{ formatDate(departureDate, 'DD-MMMM-gggg') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="payment-table">
              <div class="border-payment-type">
                <div class="total-price">
                  $ {{ `${dataBooking?.payment_detail?.total_net} ${$store.state.currency.name}` }} net due
                </div>
                <table class="b-table table-bordered table w-100 mb-0">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Price</th>
                    <th>Due Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>Price</th>
                    <td>$ {{ `${dataBooking?.payment_detail?.total_net} ${$store.state.currency.name}` }}</td>
                    <td>{{ formatDate(departureDate, 'DD-MMMM-gggg') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
      </section>
      <section id="step-5" :class="{'d-none' : step !== 4}">
        <font-awesome-icon icon="fa-solid fa-circle-check"/>
        <span>Thank you!</span>
        <p>Thank you for choosing Tweet World Travel Tour Operator & Wholesaler. Your booking request reference id:
          {{dataBooking?.order_code}}</p>
        <p>We have received your booking request and it is being processed by our operation team. We will be in touch
          with you shortly about your booking request.</p>
        <p>For further enquiries, please contact our Head Office via email <a
            href="mailto:contact@tweetworldtravel.com.au">contact@tweetworldtravel.com.au</a> or call us on <strong>1800
          519 631</strong></p>
        <div class="button-close text-right w-100">
          <b-button @click="$bvModal.hide('modal-booking')">Close</b-button>
        </div>
      </section>

    </div>
    <template #modal-footer>
      <div class="footer-modal footer-step-2 d-flex w-100 " v-if="step == 2 || step == 3" :class="{'justify-content-end': isSaveFEQuote, 'justify-content-between' : !isSaveFEQuote  }">
        <b-button class="btn-info" type="submit" @click="saveFEQuote" v-if="!isSaveFEQuote"  :disabled="loadingData"> <b-spinner v-if="loadingData" small type="grow"></b-spinner> Save As Quote</b-button>
        <b-button class="btn-main" type="submit" v-if="step == 2" @click="proceedBooking" :disabled="loadingData"> <b-spinner v-if="loadingData" small type="grow"></b-spinner> Proceed</b-button>
        <b-button class="btn-main" type="submit" v-if="step == 3 && ( tabPaymentIndex == 1 || (tabPaymentIndex == 0 && EFTMethod != 'M'))" @click="submitBooking" :disabled="loadingData"> <b-spinner v-if="loadingData" small type="grow"></b-spinner> Submit</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import countries from "@/utils/data/country";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import utils from '../../utils'
import {bookingCreateQuoteInstatrip, saveAsFrontEndQuote, convertQuoteInstatripToBooking} from "@/api/booking";

// import moment from "moment";
extend('required', {
  ...required,
  message: 'This field is required !'
});
export default {
  name: "ModalBookNow",
  props: ['product', 'passengers', 'isPrivate', 'passengerInfo', 'departureDate', 'tourGuide'],
  data() {
    // const now = new Date()
    // const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      EFTMethod: "M",
      tabPaymentIndex: 1,
      step: 1,
      countries: countries,
      loadingData: false,
      dataPassengers: {
        adult: [],
        child: [],
        infant: []
      },
      noteClient: '',
      acceptTerm: false,
      titleOptions: {
        adult: [
          {value: null, text: "Title"},
          {value: "Mr.", text: "Mr."},
          {value: "Mrs.", text: "Mrs."},
          {value: "Ms.", text: "Ms."},
          {value: "Mstr.", text: "Mstr."},
          {value: "Miss.", text: "Miss."},
          {value: "Dr.", text: "Dr."},
          {value: "Prof.", text: "Prof."},
          {value: "Sir.", text: "Sir."},
        ],
        child: [
          {value: null, text: "Title"},
          {value: "Child", text: "Child"}
        ],
        infant: [
          {value: null, text: "Title"},
          {value: "Infant", text: "Infant"}
        ]
      },
      dataBooking: {},
      isSaveFEQuote: false,

    }
  },
  methods: {
    ...utils,
    resetModalBooking: function (){
      this.step = 1;
      this.EFTMethod = 'M';
      this.loadingData = false;
      this.dataPassengers = {
        adult: [],
        child: [],
        infant: []
      }
      Object.keys(this.passengers).forEach(item => {
        for (let i = 0; i < this.passengers[item]; i++){
          this.dataPassengers[item].push({
            title: null,
            firstName: null,
            lastName: null,
            birthday: null,
            nationality: null
          })
        }
      })
      this.acceptTerm = false;
      this.noteClient = ''
      this.isSaveFEQuote = false;
    },
    submitBooking: async function (){
      let paymentMethod = 1;
      if (this.tabPaymentIndex === 1){
        paymentMethod = 3;
      } else{
        if (this.tabPaymentIndex === 0){
          paymentMethod = this.EFTMethod === 'M' ? 1 : 2
        }
      }
      const dataSubmit = {
        booking_id: this.dataBooking.booking_id,
        order_code: this.dataBooking.order_code,
        payment_method: paymentMethod,
        client_note: this.client_note,
        created_by: this.$route.query.user_id,
        payment_type: '',
        payment_amount: ''
      }
      this.loadingData = true;
       await convertQuoteInstatripToBooking(dataSubmit);
      this.step = this.step + 1;

    },
    proceedBooking: function (){
      if (!this.acceptTerm){
        this.$swal({
          type: 'warning',
          icon: 'warning',
          title: 'Please read and accept our Booking Terms & Conditions',
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
        });
      } else {
        this.step = this.step + 1;
      }
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    submitPassengers: async function () {
      let data = {
        passengers: this.dataPassengers,
        prices: {
          adult: [],
          child: [],
          infant: []
        },
        departureDate: this.departureDate,
        user_id: this.$route.query.user_id,
        trip_code: this.product.code,
        currency: this.$route.query.currency
      }
      this.passengerInfo.forEach(passenger => {
        for (let i = 0; i < passenger.amount; i++){
          data.prices[passenger.key].push(this.formatPrice({
                gross: this.product.price_gross - (this.product.price_gross) * passenger.discount,
                net: this.product.price_net - (this.product.price_net) * passenger.discount,
                currency: this.product.price_currency
              }, this.$store.state.currency.name,
              this.isPrivate, true))
        }
      });
      this.loadingData = true;
      await bookingCreateQuoteInstatrip(data).then((res) =>{
        this.step = this.step + 1;
        this.dataBooking = res.data.response
        this.loadingData = false;
      }).catch(err => {
        console.log(err);
      })
    },
    saveFEQuote: async function(){
      this.loadingData = true;
      await saveAsFrontEndQuote({
        id: this.dataBooking.booking_id,
        client_note: this.noteClient
      }).then(() => {
        this.loadingData = false;
        this.isSaveFEQuote = true;
        this.$swal({
          type: 'success',
          icon: 'success',
          title: 'Save Successfully !',
          timer: 1500,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
      })
    }
  },
  watch:{
    passengers: function(newVal) {
        this.dataPassengers = {
          adult: [],
          child: [],
          infant: []
        };
        Object.keys(newVal).forEach(item => {
          for (let i = 0; i < newVal[item]; i++){
            this.dataPassengers[item].push({
              title: null,
              firstName: null,
              lastName: null,
              birthday: null,
              nationality: null
            })
          }
        })
        console.log(this.dataPassengers)
      },
    passengerInfo: function (newVal){
      console.log(newVal);
      console.log(newVal.filter(item => item.amount))
    }

  },
  created() {
    if (this.passengers){
      Object.keys(this.passengers).forEach(item => {
        for (let i = 0; i < this.passengers[item]; i++){
          this.dataPassengers[item].push({
            title: null,
            firstName: null,
            lastName: null,
            birthday: null,
            nationality: null
          })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>