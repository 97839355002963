
const setting = {
    namespaced: true,
    state: {
        page_content: [],
        reviews: [],
        loading: false
    },
    mutations: {
        setSetting: (state, payload) => {
            let pageContentFormat = {};
            payload.page_content.forEach(item => {
                if (item.is_visible){
                    pageContentFormat[`${item.section}`] = JSON.parse(item.content);
                }
            })
            state.page_content = pageContentFormat;
            state.reviews = payload.reviews;
        },
        loading: (state, payload) => {
            if (payload) {
                document.body.classList.add('loading')
            } else{
                document.body.classList.remove('loading')
            }
            state.loading = payload;
        },
    }
}

export default setting;