<template>
  <div class="item-product">
    <b-card>
      <div class="image-product hover-pointer" @click="gotoProductDetail(product)">
        <img :src="product.thumbnail_url" alt="" />
      </div>
      <div class="content">
        <p class="country">
          <font-awesome-icon icon="fa-regular fa-map" />
          {{ product.city.name ?? "" }} -
          {{
            product.country.name == "United States of America"
              ? "USA"
              : product.country.name
          }}
        </p>
        <h5 class="title hover-pointer" @click="gotoProductDetail(product)">{{ product.title }}</h5>
        <div class="description">{{ product.summary }}</div>
        <span
          ><font-awesome-icon icon="fa-regular fa-clock" />
          {{ makeDurationString(product.duration) }}</span
        >

        <div class="footer-product">
          <span class="price-number"
            >from :
            <span>{{
              formatPrice(
                {
                  gross: product.price_gross,
                  net: product.price_net,
                  currency: product.price_currency,
                },
                $store.state.currency.name,
                  JSON.parse(product.attributes).includes('0797dc52-9529-11ea-b3d6-06b40dd1ea60')
              )
            }}</span></span
          >
          <b-btn @click="gotoProductDetail(product)" class="btn-main"
            >Book Now</b-btn
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import utils from "@/utils/index";
import { routeWebList } from "@/routes";
import UrlPattern from "url-pattern";
import favoriteRepository from "@/repositories/favorite";
export default {
  props: ["product"],
  name: "ProductComponent",
  methods: {
    ...utils,
    gotoProductDetail: function (product) {
      if (process.env.VUE_APP_ENVIRONMENT == 'production'){
        window.open(`${this.$route.query.parent_url}api-product/sightseeing/${product.slug}/${product.code}`)
      } else  {
        let pattern = new UrlPattern(routeWebList.PRODUCT_DETAIL);
        pattern = pattern.stringify({ slug: product.slug, code: product.code });
        this.$router.push(pattern);
      }
    },
    addToFavorites: function () {
      if (this.$store.state.auth.access_token === null) {
        this.$bvModal.show("login-modal");
      } else {
        this.$store.commit("setting/loading", true);
        favoriteRepository
          .add(this.product.id)
          .then((res) => {
            this.$store.commit("setting/loading", false);
            this.$store.commit("auth/setFavorite", res.data.response);
            this.$swal({
              type: "success",
              icon: "success",
              title: "Successfully added to favorites",
              timer: 1500,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: false,
            });
          })
          .catch(() => {
            this.$store.commit("setting/loading", false);
            this.$swal({
              type: "success",
              icon: "success",
              title: "Failed to add to favorites",
              timer: 1500,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: false,
            });
          });
      }
    },
    removeFavorite: function () {
      let favorite = this.$store.state.auth.favorites.filter(
        (item) => item.product.id === this.product.id
      )[0];
      this.$store.commit("setting/loading", true);
      favoriteRepository.delete(favorite.id).then((res) => {
        this.$store.commit("setting/loading", false);
        this.$store.commit("auth/setFavorite", res.data.response);
        this.$swal({
          type: "success",
          icon: "success",
          title: "Product removed from favorites",
          timer: 1500,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
      });
    },
  },
};
</script>

<style scoped>
</style>