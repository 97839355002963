

const currencies = [
    {name: 'AUD', symbol: '$'},
    {name: 'BRL', symbol: 'R$'},
    {name: 'CAD', symbol: '$'},
    {name: 'CHF', symbol: 'CHF'},
    {name: 'DKK', symbol: 'kr.'},
    {name: 'EUR', symbol: '€'},
    {name: 'GBP', symbol: '£'},
    {name: 'HKD', symbol: 'KD$'},
    {name: 'INR', symbol: '₹'},
    {name: 'JPY', symbol: '¥'},
    {name: 'NOK', symbol: 'kr'},
    {name: 'NZD', symbol: '$'},
    {name: 'SEK', symbol: 'kr'},
    {name: 'SGD', symbol: '$'},
    {name: 'TWD', symbol: 'NT$'},
    {name: 'USD', symbol: '$'},
    {name: 'ZAR', symbol: 'R'}
]

export default currencies;