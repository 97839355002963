<template>
  <main-layout>
    <b-container>
      <section id="subscribe">
        <b-row no-gutters>
          <b-col cols="5">
            <img class="w-100 banner" src="@/assets/images/subscribe_image.png" alt="">
          </b-col>
          <b-col cols="7">
            <div class="content">
              <h3>Stay up to date with useful tips vis your email newsletter</h3>
              <p>Subscribe to our newsletter and get the best travel tips and news about exciting activities around you. </p>
              <div class="input-email">
                <div class="group">
                  <input type="text" placeholder="Enter your email">
                  <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
                </div>
              </div>
              <span>We won’t spam you </span>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
  </main-layout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";

export default {
  name: "SubscribePage",
  components: {MainLayout}
}
</script>

<style scoped>

</style>