<template>
  <main-layout>
    <section id="banner">
      <img v-if="!($store.state.setting.page_content?.banner?.banner_image ?? false)" src="@/assets/images/banner.png" alt="">
      <img v-else :src="`${backendURL}${$store.state.setting.page_content.banner.banner_image}`">
      <div class="content">
        <b-container >
          <h3>{{ $store.state.setting.page_content?.banner?.title ?? 'Discover' }}</h3>
          <p>{{ $store.state.setting.page_content?.banner?.description ?? 'Amazing activities & sightseeings around you' }}</p>
          <div class="search">
            <div>
              <b-row class="justify-content-center">
                <b-col cols="8">
                  <div class="custom-input-search location">
                    <b-form-group
                        label="You next destinations or activities"
                    >
                      <b-form-input @keyup.enter="actionSearch"  v-model="querySearch" placeholder="Sydney, London, Paris, Hiking, Museum..."></b-form-input>
                    </b-form-group>
                    <img src="@/assets/images/location.png" alt="">

                  </div>
                </b-col>
<!--                <b-col cols="4">-->
<!--                  <div class="custom-input-search date">-->
<!--                    <b-form-group-->
<!--                        label="Dates"-->
<!--                    >-->
<!--                      <datepicker :disabled-dates="dateSearchConfig.disableDate"-->
<!--                                  :value="dateSearchConfig.defaultValue"></datepicker>-->
<!--                    </b-form-group>-->
<!--                    <img src="@/assets/images/calendar.png" alt="">-->
<!--                  </div>-->
<!--                </b-col>-->
                <b-col cols="2">
                  <b-btn @click="actionSearch" class="btn-main">Search <img src="@/assets/images/search.png" alt="">
                  </b-btn>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>

      </div>
    </section>
    <section id="more-info">
      <div class="container bg-white">
        <b-row class="list-info">
          <b-col cols="4">
            <div class="item-info">
              <img v-if="!($store.state.setting.page_content?.banner?.image_box_content_1 ?? false)" src="@/assets/images/star.png" alt="">
              <img v-else :src="`${backendURL}${$store.state.setting.page_content.banner.image_box_content_1}`">
              <h5>{{$store.state.setting.page_content?.banner?.title_box_content_1 ?? 'Millions of Reviews'}}</h5>
              <p>{{$store.state.setting.page_content?.banner?.description_box_content_1 ?? '' }} </p>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="item-info">
              <img v-if="!($store.state.setting.page_content?.banner?.image_box_content_2 ?? false)" src="@/assets/images/price-tag.png" alt="">
              <img v-else :src="`${backendURL}${$store.state.setting.page_content.banner.image_box_content_2}`">
              <h5>{{$store.state.setting.page_content?.banner?.title_box_content_2 ?? 'Millions of Reviews'}}</h5>
              <p>{{$store.state.setting.page_content?.banner?.description_box_content_2 ?? '' }} </p>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="item-info">
              <img v-if="!($store.state.setting.page_content?.banner?.image_box_content_3 ?? false)" src="@/assets/images/global.png" alt="">
              <img v-else :src="`${backendURL}${$store.state.setting.page_content.banner.image_box_content_3}`">
              <h5>{{$store.state.setting.page_content?.banner?.title_box_content_3 ?? 'Millions of Reviews'}}</h5>
              <p>{{$store.state.setting.page_content?.banner?.description_box_content_3 ?? '' }} </p>
            </div>
          </b-col>

        </b-row>
      </div>
    </section>
    <section id="recommend-activity" class="padding-section">
      <b-container>
        <p class="title-wrap">Recommended activities <span>for you </span></p>
        <vue-slick-carousel v-if="recommendProduct.length" class="big-arrow" :arrows="true"
                            v-bind="slickRecommendConfig">
          <div v-for="product in recommendProduct" :key="product.code">
            <product-component :product="product"></product-component>
          </div>
        </vue-slick-carousel>
        <div class="text-center" v-else>
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
        </div>
      </b-container>
    </section>
    <section id="free-cancel" class="padding-section info-line">
      <div class="container">
        <h3>{{$store.state.setting.page_content?.free_cancellation?.title ?? 'Free cancellation'}} </h3>
        <p v-if="!($store.state.setting.page_content?.free_cancellation ?? false)" class="mb-0">You’ll receive a full refund if you cancel at least 24 <br> hours in advance of most
          experiences.</p>
        <p v-else v-html="$store.state.setting.page_content?.free_cancellation.description"></p>
      </div>
    </section>
    <section id="explore" class="padding-section">
      <b-container>
        <p class="title-wrap">Explore <span>near by </span></p>
        <vue-slick-carousel v-if="nearLocation.length" class="big-arrow" :arrows="true" :slidesToShow="4" :rows="2"
                            :infinite="true">
          <div v-for="location in nearLocation" :key="location.id">
            <location-component :location="location"></location-component>
          </div>
        </vue-slick-carousel>
        <div class="text-center" v-else>
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
        </div>
      </b-container>
    </section>
    <section id="happy-customer" class="padding-section">
      <b-container>
        <h3 class="title-happy-customer">Happy Customers</h3>
        <b-row>
          <b-col v-for="review in $store.state.setting.reviews" :key="review.id" cols="6">
            <div class="item-review-customer">
              <div class="thumbnail-user">
                <img :src="review.reviewer_thumbnail_url" alt="">
              </div>
              <div class="icon-mark">
                <img src="@/assets/images/mark.png" alt="">
              </div>
              <div class="review-content">
                <p>{{review.title}}</p>
                <p>{{review.content}} </p>
              </div>
              <p class="customer-name">
                {{review.reviewer_name}}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="make-plan-this-weekend" class="padding-section">
      <b-container>
        <p class="title-wrap">Make Plans <span> this weekend</span></p>
        <vue-slick-carousel v-if="weekProduct.length" :arrows="true" v-bind="slickRecommendConfig" class="big-arrow">
          <div v-for="product in weekProduct" :key="product.code">
            <product-component :product="product"></product-component>
          </div>
        </vue-slick-carousel>
        <div class="text-center" v-else>
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
        </div>
      </b-container>
    </section>
    <section id="support-full" class="padding-section info-line">
      <div class="container">
        <h3> {{$store.state.setting.page_content?.support_247?.title ?? '24/7 Support'}} </h3>

        <p v-if="!($store.state.setting.page_content?.support_247 ?? false)" class="mb-0">New price? New plan? No problem. We’re here to help, 24/7..</p>
        <p v-else v-html="$store.state.setting.page_content?.support_247.description"></p>
      </div>
    </section>
    <section id="top-destination" class="padding-section">
      <b-container>
        <p class="title-wrap">Top <span>Destinations</span></p>
        <b-row v-if="topDestination.length">
          <b-col v-for="destination in topDestination" :key="destination.id" cols="3" class="col-destination">
            <div class="item-destination" @click="redirectToPlacePage(destination.id)">
              <div class="blur"></div>
              <img :src="destination.thumbnail_url" :alt="destination.name">
              <span>{{ destination.name }}</span>
            </div>
          </b-col>
        </b-row>
        <div class="text-center" v-else>
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
<!--          <b-spinner type="grow" label="Spinning"></b-spinner>-->
        </div>
      </b-container>
    </section>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/layouts/MainLayout";
// import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ProductComponent from "@/components/product/ProductComponent";
import LocationComponent from "@/components/location/LocationComponent";
import productRepository from '@/repositories/product'
import countryRepository from "@/repositories/country";
import UrlPattern from "url-pattern";
import {routeWebList} from "@/routes";

export default {
  name: "HomePage",
  components: {LocationComponent, ProductComponent, MainLayout, VueSlickCarousel},
  data() {
    return {
      windowHeight: window.innerHeight,
      dateSearchConfig: {
        defaultValue: new Date(),
        disableDate: {
          to: moment().subtract(1, 'days').toDate()
        }
      },
      slickRecommendConfig: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 2,
        slidesPerRow: 1
      },
      recommendProduct: [],
      nearLocation: [],
      weekProduct: [],
      topDestination: [],
      querySearch: '',
      backendURL: process.env.VUE_APP_BACKEND_URL
    }
  },
  methods: {
    actionSearch: function (){
      if (this.querySearch){
        let pattern = new UrlPattern(routeWebList.PRODUCTS);
        pattern = pattern.stringify() + `?q=${this.querySearch}`;
        this.$router.push(pattern);
      }
    },
    redirectToPlacePage: function (id){
      let pattern = new UrlPattern(routeWebList.PRODUCTS_BY_TYPE);
      pattern = pattern.stringify({type: 'place', id: id});
      this.$router.push(pattern);
    }
  },
  async created() {
    if (localStorage.getItem('feature_post_home_page') !== null){
      this.recommendProduct = JSON.parse(localStorage.getItem('feature_post_home_page'));
    }
    if (localStorage.getItem('near_location') !== null){
      this.nearLocation = JSON.parse(localStorage.getItem('near_location'));
    }
    if (localStorage.getItem('week_product') !== null){
      this.weekProduct = JSON.parse(localStorage.getItem('week_product'));
    }
    if (localStorage.getItem('top_destination') !== null){
      this.topDestination = JSON.parse(localStorage.getItem('top_destination'));
    }
    productRepository.featured().then(res => {
      this.recommendProduct = res;
      localStorage.setItem('feature_post_home_page', JSON.stringify(res));
    });
    countryRepository.cities().then(res => {
      let locationHasImage = res.filter(item => item.thumbnail_url !== null)
      this.nearLocation = locationHasImage;
      localStorage.setItem('near_location', JSON.stringify(locationHasImage));
      let listLocationId = this.nearLocation.map(location => location.id);
      productRepository.productByLocations(listLocationId).then(res => {
        localStorage.setItem('week_product', JSON.stringify(res));
        this.weekProduct = res;
      })
    });
    productRepository.topDestination().then(res => {
      this.topDestination = res;
      localStorage.setItem('top_destination', JSON.stringify(res));
    });

  }
}
</script>

<style scoped>
</style>