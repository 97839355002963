import utils from '../../utils/index';
import {listLanguageShow} from "@/utils/data/country";
let languageLocal = utils.getItemLocalStorage('language') ?? 'EN';
const language = {
    namespaced: true,
    state: {
        langCode: languageLocal,
        flag: `https://flagcdn.com/w40/${ listLanguageShow.filter(language => language.langCode === languageLocal)[0].code.toLowerCase()}.png`
    },
    mutations: {
        change: (state, payload) => {
            let countryCode = listLanguageShow.filter(language => language.langCode === payload.langCode)[0].code;
            state.flag = `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`
            state.langCode = payload.langCode;
        }
    }
}

export default language;