import xhr from "@/api/config/xhr";
import endPoint from "@/api/config/endPoint";
import {getURL} from "@/api/config/endPoint";

export const getCountryByIp =  async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.GET_CITY_IN_COUNTRY, routeParams, queryParam));
}

export const getTopDestination = async () => {
    return await xhr.get(getURL(endPoint.TOP_DESTINATION));
}