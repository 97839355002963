<template>
<div class="item-location" @click="redirect" v-if="location.thumbnail_url !== null">
  <b-card>
    <div class="image-location">
      <img :src="location.thumbnail_url" alt="">
    </div>
    <h4>{{location.name}}</h4>
<!--    <p>{{location.total_product}} Tours and Activities</p>-->
  </b-card>
</div>
</template>

<script>
import UrlPattern from "url-pattern";
import {routeWebList} from "@/routes";

export default {
  props: ['location'],
  name: "LocationComponent",
  methods: {
    redirect: function (){
      let pattern = new UrlPattern(routeWebList.PRODUCTS_BY_TYPE);
      pattern = pattern.stringify({type: 'place', id: this.location.id});
      this.$router.push(pattern);
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>