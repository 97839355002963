import {
    getAvailability,
    getAvailabilityList, getFilterList,
    getProductByCategories,
    getProductByLocations,
    getProductDetail, getProducts
} from '@/api/product';
import utils from '@/utils/index';
import {getTopDestination} from "@/api/country";

const productRepository = {
    recommend: async () => {
        try {
            let categoriesLocal = utils.getItemLocalStorage('categories') ?? [];
            let response = await getProductByCategories({}, {categories: JSON.stringify(categoriesLocal), limit: 12});
            let data = response.data;
            if (data.meta.status !== 200) return [];
            return data.response;
        } catch (e){
            return  []
        }
    },
    productByLocations : async (locations) => {
        try {
            let response = await getProductByLocations({}, {locations: JSON.stringify(locations), limit: 12});
            let data = response.data;
            if (data.meta.status !== 200) return [];
            return data.response;
        } catch (e){
            return []
        }
    },
    findByCode: async (code) => {
        try {
            let response = await getProductDetail({code: code});
            let categoriesLocal = utils.getItemLocalStorage('categories') ?? [];
            let data = response.data;
            data.response.categories.forEach(category => categoriesLocal.push(category.id));
            if (categoriesLocal.length > 10){
                categoriesLocal = categoriesLocal.splice(categoriesLocal.length - 10);
            }
            utils.setItemLocalStorage('categories', categoriesLocal);
            if (data.meta.status !== 200) return {};
            return  data.response;
        } catch (e){
            return {}
        }
    },
    topDestination : async () => {
        try {
            let response = await getTopDestination();
            return  response.data.response;
        } catch (e){
            return [];
        }
    },
    availabilityList: async (id, date) => {
        try {
            let response = await getAvailabilityList({}, {product_id: id, date: date});
            return response.data.response;
        } catch (e){
            return [];
        }
    },
    availability: async (id, options) => {
        try {
            let response = await getAvailability({id: id}, {options: JSON.stringify(options)} );
            return  response.data.response;
        } catch (e){
            return {}
        }
    },
    featured: async () => {
        try {
            let response = await getProducts({}, {is_featured: true, perPage: 8});
            return response.data.response.data;
        } catch (e){
            return [];
        }
    },
    filterList: async (data = {}) => {
        try {
            let response = await getFilterList({}, data);
            return  response.data.response;
        } catch (e){
            return [];
        }
    },
    index: async (filterOption, page = 1) => {
        try {
            let attributes = JSON.stringify(filterOption.attributes);
            let categories = JSON.stringify(filterOption.categories);
            let countries = JSON.stringify(filterOption.countries);
            let perPage = 72;
            let response = await getProducts({}, {
                attributes: attributes,
                categories: categories,
                countries: countries,
                page: page,
                perPage: perPage,
                keyword: filterOption.keyword,
                sortPrice: filterOption.sortPrice
            });
            return response.data.response;
        } catch (e){
            console.log(e)
        }
    }
}

export default productRepository;

