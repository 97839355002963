import xhr from "@/api/config/xhr";
import endPoint from "@/api/config/endPoint";
import {getURL} from "@/api/config/endPoint";

export const postRegister =  async data => {
    return await xhr.post(getURL(endPoint.USER_REGISTER), data);
}

export const postLogin =  async data => {
    return await xhr.post(getURL(endPoint.USER_LOGIN), data);
}

export const postForgetPassword = async data => {
    return await xhr.post(getURL(endPoint.USER_FORGET_PASSWORD), data);
}

export const postCheckForgetPasswordToken = async  data => {
    return await xhr.post(getURL(endPoint.USER_CHECK_FORGET_PASSWORD_TOKEN), data);
}

export const putUpdatePasswordForget = async  data => {
    return await xhr.put(getURL(endPoint.USER_FORGET_UPDATE_PASSWORD), data);
}

export const putUpdateProfile = async data => {
    return await xhr.put(getURL(endPoint.USER_UPDATE), data);
}

export const getInfo = async () => {
    return await xhr.get(getURL(endPoint.USER_DETAIL));
}