import xhr from "@/api/config/xhr";
import endPoint from "@/api/config/endPoint";
import {getURL} from "@/api/config/endPoint";

export const getProductByCategories =  async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.PRODUCT_BY_CATEGORIES, routeParams, queryParam));
}

export const getProductByLocations =  async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.PRODUCT_BY_LOCATIONS, routeParams, queryParam));
}

export const getProductDetail = async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.PRODUCT_DETAIL, routeParams, queryParam));
}

export const getAvailabilityList = async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.GET_AVAILABILITY_LIST, routeParams, queryParam));
}

export const getAvailability = async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.GET_AVAILABILITY, routeParams, queryParam));
}

export const getProducts = async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.PRODUCT_LIST, routeParams, queryParam));
}

export const getFilterList = async (routeParams = {}, queryParam = {}) => {
    return await xhr.get(getURL(endPoint.PRODUCT_FILTER_LIST, routeParams, queryParam));
}
