<template>
  <div>
    <div :class="{bold: isParent}" @click="toggle">
      <span v-if="item.children && item.children.length">{{ item.name }}</span>
      <b-form-checkbox @change="setFilterByType(item.id, type)" v-else :value="item.id" >{{item.name}}</b-form-checkbox>
      <font-awesome-icon class="icon-style-filter" v-if="isParent" :icon="`fa-solid ${isOpen ? 'fa-minus' : 'fa-plus'}`" />
    </div>
    <div class="sub-menu" v-show="item.name !== 'Places' ? isOpen : !isOpen">
      <b-form-input :placeholder="`Enter ${type === 'countries' ? 'place name' : (type === 'categories' ? 'category name' : 'advance name')}`" class="mt-1 mb-1" v-if="root" type="search"></b-form-input>
      <tree-item
          class="item"
          v-show="item.children"
          v-for="(child, index) in item.children"
          :key="index"
          :set-filter-by-type="setFilterByType"
          :type="type"
          :item="child"
      >
      </tree-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeItem",
  props: ['item', 'type', 'setFilterByType', 'root'],
  data() {
    return {
      isOpen: true
    }
  },
  computed: {
    isParent: function () {
      return this.item.children && this.item.children.length && this.item.children[0] !== 1;
    }
  },
  methods: {
    toggle: function () {
      if (this.isParent) {
        this.isOpen = !this.isOpen;
      }
    }
  },
  created() {
    
  }
}
</script>

<style scoped>

</style>