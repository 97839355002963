<template>
  <div>
    <div :class="{bold: isParent}" @click="toggle">
      <span v-if="item.children && item.children.length">{{ item.name }}</span>
      <router-link v-else :to="`/products/${type}/${item.id}`">{{item.name}}</router-link>
      <font-awesome-icon v-if="isParent" :icon="`fa-solid ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`"/>
    </div>
    <div class="sub-menu"  v-show="isOpen">
      <tree-item-sidebar
          class="item"
          v-for="(child, index) in item.children"
          :key="index"
          :type="type"
          :item="child"
      >
      </tree-item-sidebar>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeItemSidebar",
  props: ['item', 'type'],
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    isParent: function () {
      return this.item.children && this.item.children.length;
    }
  },
  methods: {
    toggle: function () {
      if (this.isParent) {
        this.isOpen = !this.isOpen;
      }
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>