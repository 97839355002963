import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCircleQuestion,
    faCircleUser,
    faHeart,
    faMap,
    faClock,
    faUser,
    faCalendarDays
} from '@fortawesome/free-regular-svg-icons'
import {
    faBars,
    faDollarSign,
    faCartShopping,
    faMagnifyingGlassLocation,
    faArrowRightFromBracket,
    faCircleArrowRight,
    faBoltLightning,
    faAngleRight,
    faBan,
    faMapPin,
    faLocationCrosshairs,
    faCircleInfo,
    faPlus,
    faUserGroup,
    faGlobe,
    faCrosshairs,
    faLightbulb,
    faUtensils,
    faComment,
    faRainbow,
    faMessage,
    faCheck,
    faMinus,
    faCircleCheck,
    faChevronUp,
    faChevronDown,
    faRightToBracket,
    faHeart as faHeartSolid,
    faTrash,
    faSpa,
    faUsers,
    faUser as faUserSolid,
    faReceipt,
    faStar
} from '@fortawesome/free-solid-svg-icons'
library.add(
    faTrash,
    faHeartSolid,
    faBars,
    faDollarSign,
    faCartShopping,
    faCircleQuestion,
    faCircleUser,
    faHeart,
    faMagnifyingGlassLocation,
    faMap,
    faClock,
    faArrowRightFromBracket,
    faUser,
    faCircleArrowRight,
    faBoltLightning,
    faAngleRight,
    faBan,
    faMapPin,
    faLocationCrosshairs,
    faCircleInfo,
    faPlus,
    faUserGroup,
    faGlobe,
    faCrosshairs,
    faLightbulb,
    faUtensils,
    faComment,
    faRainbow,
    faMessage,
    faCheck,
    faCalendarDays,
    faMinus,
    faCircleCheck,
    faChevronUp,
    faChevronDown,
    faRightToBracket,
    faSpa,
    faUsers,
    faUserSolid,
    faReceipt,
    faStar
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
