import xhr from "@/api/config/xhr";
import endPoint from "@/api/config/endPoint";
import {getURL} from "@/api/config/endPoint";

export const bookingCreateQuote = async (data) => {
    return await xhr.post(getURL(endPoint.BOOKING_CREATE_QUOTE), data);
}

export const bookingCreateQuoteInstatrip = async data => {
    return await xhr.post(getURL(endPoint.BOOKING_CREATE_QUOTE_INS), data);
}

export const saveAsFrontEndQuote = async data => {
    return await xhr.post(getURL(endPoint.BOOKING_SAVE_AS_FRONT_END_QUOTE), data);
}

export const convertQuoteInstatripToBooking = async  data => {
    return await xhr.post(getURL(endPoint.BOOKING_CONVERT_QUOTE_INSTATRIP_TO_BOOKING), data);

}

export const bookingConvertQuoteToBooking = async (data) => {
    return await xhr.post(getURL(endPoint.BOOKING_CONVERT_QUOTE_TO_BOOKING), data);
}

export const getBookingList = async () => {
    return await xhr.get(getURL(endPoint.BOOKING_LIST));
}

export const findById = async (id) => {
    return await xhr.get((getURL(endPoint.BOOKING_DETAIL, {id: id})));
}