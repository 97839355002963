<template>
  <MainLayout>
    <div class="container  padding-content  ">
      <div class="content-page box-shadow" style="border-radius: 10px">
        <h4 class="mb-4">Profile Settings</h4>
        <b-row>
          <b-col cols="7">
            <ValidationObserver v-slot="{handleSubmit}">
              <form @submit.prevent="handleSubmit(updateProfile)">
                <ValidationProvider rules="requiredProfile|emailProfile" v-slot="{errors}">
                  <b-form-group label="Email">
                    <b-form-input
                        type="text"
                        v-model="user.email"
                        :class="{'is-invalid' : errors.length}"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
                <b-row>
                  <b-col cols="6">
                    <ValidationProvider rules="requiredProfile" v-slot="{errors}">
                      <b-form-group label="First Name">
                        <b-form-input
                            type="text"
                            v-model="user.first_name"
                            :class="{'is-invalid' : errors.length}"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="6">
                    <ValidationProvider rules="requiredProfile" v-slot="{errors}">
                      <b-form-group label="Last Name">
                        <b-form-input
                            type="text"
                            v-model="user.last_name"
                            :class="{'is-invalid' : errors.length}"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <ValidationProvider rules="requiredProfile" v-slot="{errors}">
                      <b-form-group label="Date of Birth">
                        <b-form-input
                            type="date"
                            :class="{'is-invalid' : errors.length}"
                            v-model="user.date_of_birth"
                            :max="formatDate(new Date(), 'YYYY-MM-DD')"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>

                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="6">
                    <b-row no-gutters>
                      <b-col cols="4">
                        <ValidationProvider rules="requiredProfile" v-slot="{errors}">
                          <b-form-group label="Country">
                            <v-select :clearable="false"
                                      :options="countries.map(item => `+${item.phone}`).filter((value, index, self) => self.indexOf(value) === index)"
                                      v-model="user.country_code" :class="{'is-invalid' : errors.length}"></v-select>
                            <b-form-invalid-feedback class="invalid-feedback d-block">{{
                                errors[0]
                              }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col cols="8">
                        <ValidationProvider rules="requiredProfile|numericProfile" v-slot="{errors}">
                          <b-form-group label="Phone Number">
                            <b-form-input
                                type="text"
                                v-model="user.phone_number"
                                :class="{'is-invalid' : errors.length}"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback class="invalid-feedback d-block">{{
                                errors[0]
                              }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">
                    <ValidationProvider rules="requiredProfile" v-slot="{errors}">
                      <b-form-group>
                        <b-form-group label="Gender">
                          <select v-model="user.gender" :class="{'is-invalid' : errors.length}" class="form-control">
                            <option value="" selected disabled>Gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="3">Other</option>
                          </select>
                          <b-form-invalid-feedback class="invalid-feedback d-block">{{
                              errors[0]
                            }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="9">
                    <ValidationProvider rules="requiredProfile" v-slot="{errors}">
                      <b-form-group label="Address">
                        <b-form-input v-model="user.address">

                        </b-form-input>
                        <b-form-invalid-feedback class="invalid-feedback d-block">{{
                            errors[0]
                          }}
                        </b-form-invalid-feedback>

                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <div class="d-flex justify-content-end">
                  <b-button type="submit" class="btn btn-main">Update</b-button>
                </div>
              </form>
            </ValidationObserver>

          </b-col>
          <b-col cols="1">
            <div class="line-vertical">
              <span></span>
            </div>
          </b-col>
          <b-col cols="4">
            <ValidationObserver v-slot="{handleSubmit}">
              <form @submit.prevent="handleSubmit(updatePassword)">
                <ValidationProvider rules="requiredProfile|minPassword:8" name="password" v-slot="{errors}">
                  <b-form-group label="New Password">
                    <b-form-input type="password" v-model="password" :class="{'is-invalid' : errors.length}">
                    </b-form-input>
                    <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider rules="requiredProfile|confirmPassword:@password" v-slot="{errors}">
                  <b-form-group label="New Password Confirm">
                    <b-form-input type="password" v-model="password_confirm" :class="{'is-invalid' : errors.length}">
                    </b-form-input>
                    <b-form-invalid-feedback class="invalid-feedback d-block">{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
                <div class="d-flex justify-content-end">
                  <b-button type="submit" class="btn w-100 btn-main">Change Password</b-button>
                </div>
              </form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import {extend} from 'vee-validate';
import {required, email, numeric, min} from 'vee-validate/dist/rules';
import countries from '../../utils/data/country';
import utils from '../../utils'
import userRepository from "@/repositories/user";

extend('requiredProfile', {
  ...required,
  message: 'This field is required !'
});
extend('emailProfile', {
  ...email,
  message: 'This field must be a valid email address !'
});
extend('numericProfile', {
  ...numeric,
  message: 'This filed must be a number !'
});
extend('minPassword', {
  ...min,
  message: 'This field must be at least 8 characters.'
});
extend('confirmPassword', {
  params: ['target'],
  validate(value, {target}) {
    return value === target;
  },
  message: 'The password confirm does not match the password'
});
export default {
  name: "ProfilePage",
  components: {MainLayout},
  data() {
    return {
      user: {
        email: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        country_code: '',
        phone_number: '',
        gender: '',
        address: ''
      },
      countries: countries,
      password: '',
      password_confirm: ''
    }
  },
  methods: {
    ...utils,
    updateProfile: function () {
      this.$store.commit('setting/loading', true);
      userRepository.updateProfile(this.user).then((res) => {
        if (res) {
          this.$store.commit('setting/loading', false);
          this.$store.commit('auth/update', {access_token: this.$store.state.auth.access_token, user: this.user})
          this.$swal({
            type: 'success',
            icon: 'success',
            timer: 1500,
            title: 'Update successful',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else {
          this.$swal({
            type: 'error',
            icon: 'error',
            timer: 1500,
            title: 'Update failed',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }

      }).catch(e => {
        this.$store.commit('setting/loading', false);
        console.log(e);
      })
    },
    updatePassword: function () {
      let user = {...this.user, password: this.password}
      this.$store.commit('setting/loading', true);
      userRepository.updateProfile(user).then((res) => {
        if (res) {
          this.$store.commit('setting/loading', false);
          this.$store.commit('auth/update', {access_token: this.$store.state.auth.access_token, user: this.user})
          this.$swal({
            type: 'success',
            icon: 'success',
            timer: 1500,
            title: 'Update successful',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else {
          this.$swal({
            type: 'error',
            icon: 'error',
            timer: 1500,
            title: 'Update failed',
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }

      }).catch(e => {
        this.$store.commit('setting/loading', false);
        console.log(e);
      })
    }
  },
  async created() {
    this.$store.commit('setting/loading', true);
    if (this.$store.state.auth.access_token === null) this.$router.push('/');
    let request = await userRepository.show();
    this.user = request.data.response
    this.$store.commit('setting/loading', false);
  }
}
</script>

<style scoped>

</style>