import currencies from "@/utils/data/currencies";
import utils from '../../utils/index';

let currencyLocal = utils.getItemLocalStorage('currency') ?? {name: 'USD', symbol: '$'};
const currency = {
    namespaced: true,
    state: {
        name:  currencyLocal.name,
        symbol: currencyLocal.symbol
    },
    mutations: {
        change: (state, payload) => {
            state.name = payload.name;
            let newSymbol = currencies.filter(currency => currency.name === payload.name)[0].symbol;
            state.symbol = newSymbol;
            utils.setItemLocalStorage('currency', {name: payload.name, symbol: newSymbol});
        }
    }
}

export default currency;