import moment from "moment";
import {v4 as uuidv4} from "uuid";

const setItemLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

const getItemLocalStorage = key => {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
}

const formatPrice = (price, currentCurrency, isPrivate, noCurrency = false) => {

    if (price == null) return '0';
    let rate = 1;
    if (getItemLocalStorage('currencyRate')){
        try {
            let arrayCurrency = getItemLocalStorage('currencyRate');
            rate = arrayCurrency.filter(currency => currency.code == currentCurrency)[0].rate;
        } catch (e){
            // console.log('can not find currency exchange');
        }
    }
    let resultPrice =  (price.gross / rate) + (price.gross / rate)* (isPrivate ? 0.45 : 0.2);
    return noCurrency ? (resultPrice).toFixed(1) : '$ ' + (resultPrice).toFixed(1) + ' ' + currentCurrency;
}

const formatDurationISO8601 = duration => {
    const d = moment.duration(duration);
    let stringFormat = '';
    if(d.minutes()) stringFormat = `${d.minutes()} minutes`
    if (d.hours()) stringFormat = `${d.minutes() > 0 ?  d.hours() + 1 : d.hours()} hours `;
    if(d.days()) stringFormat = `${d.days()} ${d.days() > 1 ? 'days' : 'day'}`;
    return stringFormat;
}


const makeDurationString = duration => {
    let durationString = '';
    if (duration){
        duration = duration.split(" - ");
        if (duration.length){
            durationString += formatDurationISO8601(duration[0]);
            if (duration[1] && duration[0] != duration[1]) durationString += ` - ${formatDurationISO8601(duration[1])}`
        }
    }
    return durationString;
}

const randomUUID = (string = '') => uuidv4() + string;

const renderIconAttrProduct = type => {
    let icon = null;
    switch (type){
        case 'Good To Know':
            icon = "fa-solid fa-circle-info"
            break;
        case 'Traveler Type':
            icon = "fa-solid fa-user-group"
            break;
        case 'Added Extras':
            icon = "fa-solid fa-plus"
            break;
        case 'Guide Language':
            icon = "fa-solid fa-globe"
            break;
        case 'Duration':
            icon = "fa-solid fa-clock"
            break;
        case 'Tour Type':
            icon = "fa-solid fa-crosshairs"
            break;
        case 'Accessibility':
            icon = "fa-solid fa-lightbulb"
            break;
        case  'Food and Drink Included' :
            icon = "fa-solid fa-utensils"
            break;
        case  'Meeting Type':
            icon = "fa-solid fa-message";
            break;
        case 'Style':
            icon = "fa-solid fa-rainbow";
            break;
    }
    return icon;
}

const formatDate = (date , type) => {
    return moment(date).format(type);
}


export default {
    setItemLocalStorage,
    getItemLocalStorage,
    formatPrice,
    makeDurationString,
    randomUUID,
    renderIconAttrProduct,
    formatDate
}
