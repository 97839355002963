import axios from "axios";
import store from "@/stores";

const instance = configHeader => axios.create({
    headers: {...configHeader}
});

const get = async (url, params) => {

    const axios = instance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.state.auth.access_token}`
    });
    try {
        let request = await axios.get(url, {
            params: params
        });
        return request;
    } catch (e){
        if (e.response.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
    }
};

const post = async (url, data) => {
    const axios = instance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.state.auth.access_token}`
    });
    try {
        let request = await axios.post(url, data);
        if (request.data.meta.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
        return request;
    } catch (e){
        if (e.response.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
    }

};

const put = async (url, data) => {
    const axios = instance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.state.auth.access_token}`
    });
    try {
        let request = await axios.put(url, data);
        if (request.data.meta.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
        return request;
    } catch (e){
        if (e.response.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
    }

};

const _delete = async (url, data) => {
    const axios = instance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.state.auth.access_token}`
    });
    try {
        let request = await axios.delete(url, data);
        if (request.data.meta.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
        return request;
    } catch (e){
        if (e.response.status === 401){
            store.commit('auth/update', {access_token: null, user: null});
        }
    }
}

export default {
    get: get,
    post: post,
    put: put,
    _delete: _delete
}