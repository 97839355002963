<template>
  <main-layout>
    <section id="banner">
      <img v-if="!($store.state.setting.page_content?.banner?.banner_image ?? false)" src="@/assets/images/banner.png" alt="" style="height: 65vh;max-height: 465px;">
      <img v-else :src="`${backendURL}${$store.state.setting.page_content.banner.banner_image}`" alt="">
      <div class="content">
        <b-container >
          <h3>{{ $store.state.setting.page_content?.banner?.title ?? 'Discover' }}</h3>
          <p>{{ $store.state.setting.page_content?.banner?.description ?? 'Amazing activities & sightseeings around you' }}</p>
          <div class="search">
            <div>
              <b-row class="justify-content-center">
                <b-col cols="8">
                  <div class="custom-input-search location">
                    <b-form-group
                        label="You next destinations or activities"
                    >
                      <b-form-input @keyup.enter="actionSearch"  v-model="filterValue.keyword" placeholder="Sydney, London, Paris, Hiking, Museum..."></b-form-input>
                    </b-form-group>
                    <img src="@/assets/images/location.png" alt="">

                  </div>
                </b-col>
                <!--                <b-col cols="4">-->
                <!--                  <div class="custom-input-search date">-->
                <!--                    <b-form-group-->
                <!--                        label="Dates"-->
                <!--                    >-->
                <!--                      <datepicker :disabled-dates="dateSearchConfig.disableDate"-->
                <!--                                  :value="dateSearchConfig.defaultValue"></datepicker>-->
                <!--                    </b-form-group>-->
                <!--                    <img src="@/assets/images/calendar.png" alt="">-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <b-col cols="2">
                  <b-btn @click="actionSearch" class="btn-main">Search <img src="@/assets/images/search.png" alt="">
                  </b-btn>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>

      </div>
    </section>
    <div ref="scrollToItem" style="height: 160px">&nbsp;</div>
    <div class="container padding-content large products">
      <b-row>
        <b-col cols="2">
          <b-form-input placeholder="Keyword"  v-model="filterValue.keyword" @change="changeFilter('keyword')"
                        class="mb-1" type="search"></b-form-input>
          <!-- <b-form-invalid-feedback class="d-block" v-if="!filterValue.keyword && !$route.params.type" id="input-live-feedback">
            Please enter keyword
          </b-form-invalid-feedback> -->
          <b-form-select v-model="filterValue.sortPrice" @change="changeFilter" class="mb-3 mt-2">
            <b-form-select-option value="">Select price sort</b-form-select-option>
            <b-form-select-option value="DESC">High to low</b-form-select-option>
            <b-form-select-option value="ASC">Low to high</b-form-select-option>
          </b-form-select>
          <tree-item :item="categories" type="categories" :set-filter-by-type="setFilterByType" class="root-tree-item"></tree-item>
          <tree-item :item="attributes" type="attributes" :set-filter-by-type="setFilterByType" class="root-tree-item"></tree-item>
          <tree-item :item="countries" type="countries" :set-filter-by-type="setFilterByType" class="root-tree-item"></tree-item>
        </b-col>
        <b-col cols="10" class="position-relative">
          <b-row no-gutters>
            <b-col v-for="product in products.data" :key="product.id" cols="3" >
              <product-component :product="product"/>
            </b-col>
          </b-row>
          <div v-if="Object.keys(products).length" class="pagination-area">
            <b-pagination
                v-model="currentPage"
                :total-rows="products.total"
                :per-page="products.per_page"
                first-number
                last-number
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="d-none">
      <iframe id="iframe-agent-portal" src="" />
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TreeItem from '@/components/treeview/TreeItem'
import productRepository from "@/repositories/product";
import ProductComponent from "@/components/product/ProductComponent";
import UrlPattern from "url-pattern";
import {routeWebList} from "@/routes";
import utils from '@/utils/index';

export default {
  name: "ProductsPage",
  components: {ProductComponent, MainLayout, TreeItem},
  data() {
    return {
      categories: {name: "Categories", children: utils.getItemLocalStorage('sidebarData')?.categories ?? [1]},
      attributes: {name: "Advanced Filters", children: utils.getItemLocalStorage('sidebarData')?.attributes ?? [1]},
      countries: {name: "Places", children: utils.getItemLocalStorage('sidebarData')?.countries ?? [1]},
      // categories: {name: "Categories", children: [1]},
      // attributes: {name: "Advanced Filters", children: [1]},
      // countries: {name: "Places", children: [1]},
      filterValue: {
        categories: this.$route.name === 'products_by_type' && this.$route.params.type === 'category' ? [this.$route.params.id] : [],
        attributes: this.$route.name === 'products_by_type' && this.$route.params.type === 'attribute' ? [this.$route.params.id] : [],
        countries: this.$route.name === 'products_by_type' && this.$route.params.type === 'place' ? [this.$route.params.id] : [],
        keyword: this.$route.query.q ?? '',
        sortPrice: '',
      },
      products: {},
      currentPage: 1,
      backendURL: process.env.VUE_APP_BACKEND_URL,
      parentURL: this.$route.query.parent_url

    }
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.scrollToItem;
      if (el) {
        setTimeout(() => el.scrollIntoView({behavior: 'smooth'}), 500);
      }
    },
    actionSearch: function (){
      let currentQuery = this.$route.query

      if (this.filterValue.keyword){
        delete currentQuery.q;
        this.$router.push({
          path: routeWebList.PRODUCTS,
          query: {...{q: this.filterValue.keyword}, ...currentQuery}
        });
      } else {
        delete currentQuery.q;
        let pattern = new UrlPattern(routeWebList.PRODUCTS);
        const result = '?' + new URLSearchParams({...currentQuery, ...{t: new Date().getTime()}}).toString();
        this.$router.push(pattern.stringify() + result);
      }
      this.scrollToElement();
    },
    setFilterByType: function (id, type) {
      let filterValueByType = this.filterValue[`${type}`];
      if (filterValueByType.includes(id)) {
        filterValueByType = filterValueByType.filter(item => item !== id)
      } else {
        filterValueByType.push(id);
      }
      let newFilterValue = {...this.filterValue};
      newFilterValue[`${type}`] = filterValueByType;
      this.filterValue = newFilterValue;
    },
    changeFilter: function (type = '') {
      // if (this.filterValue.keyword){
        if (type === 'keyword') {
          let currentQuery = this.$route.query

          delete currentQuery.q;
          let pattern = new UrlPattern(routeWebList.PRODUCTS);
          const result = '?' + new URLSearchParams({...currentQuery, ...{t: new Date().getTime()}}).toString();
          pattern = pattern.stringify() + result;
          this.$router.push(pattern);
        } else {
          if (this.currentPage !== 1) {
            this.currentPage = 1;
          } else {
            this.$store.commit('setting/loading', true);
            window.scrollTo(0, 0);
            productRepository.index(this.filterValue).then(res => {
              this.$store.commit('setting/loading', false);
              this.products = res;
            });
          }
        }
      // }
    }
  },
  watch: {
    filterValue(newFilterValue) {

      // if (this.filterValue.keyword){
        if (this.currentPage === 1) {
          this.$store.commit('setting/loading', true);
          window.scrollTo(0, 0);
          productRepository.index(newFilterValue).then(res => {
            this.$store.commit('setting/loading', false);
            this.products = res;
          })
        } else {
          this.currentPage = 1;
        }
      // }
    },
    currentPage(newPage) {
      // if (this.filterValue.keyword  || this.$route.params.type){
        this.$store.commit('setting/loading', true);
        window.scrollTo(0, 0);
        productRepository.index(this.filterValue, newPage).then(res => {
          this.$store.commit('setting/loading', false);
          this.products = res;
        })
      // }
    }
  },
  created() {

    this.$store.commit('setting/loading', true);
    let dataFilterList = {
      keyword: this.filterValue.keyword,
      category: this.filterValue.categories.length ? this.filterValue.categories[0]: '',
      attribute: this.filterValue.attributes.length ? this.filterValue.attributes[0]: '',
      place: this.filterValue.countries.length ? this.filterValue.countries[0] : '',
    }

    productRepository.filterList(dataFilterList).then(res => {
      this.categories = {...this.categories, children: res.categories};
      this.attributes = {...this.attributes, children: res.attributes};
      this.countries = {...this.countries, children: res.countries};
    });
    if (localStorage.getItem('default-products') != null){
      this.products = JSON.parse(localStorage.getItem('default-products'));
    }
    productRepository.index(this.filterValue).then(res => {
      this.$store.commit('setting/loading', false);
      this.products = res;
      localStorage.setItem('default-products', JSON.stringify(res));
    });
    // if ((!this.filterValue.keyword || !this.$route.query.q) && !this.$route.params.type ){
    //   this.$router.push('/');
    // }
  },
  beforeRouteUpdate(to, from, next){
    let newFilterValue = {...this.filterValue};
    newFilterValue.categories = to.name === 'products_by_type' && to.params.type === 'category' ? [to.params.id] : [];
    newFilterValue.attributes = to.name === 'products_by_type' && to.params.type === 'attribute' ? [to.params.id] : [];
    newFilterValue.countries = to.name === 'products_by_type' && to.params.type === 'place' ? [to.params.id] : [];
    this.filterValue = newFilterValue;
    let dataFilterList = {
      keyword: this.filterValue.keyword,
      category: this.filterValue.categories.length ? this.filterValue.categories[0]: '',
      attribute: this.filterValue.attributes.length ? this.filterValue.attributes[0]: '',
      place: this.filterValue.countries.length ? this.filterValue.countries[0] : '',
    }
    productRepository.filterList(dataFilterList).then(res => {
      this.categories = {...this.categories, children: res.categories};
      this.attributes = {...this.attributes, children: res.attributes};
      this.countries = {...this.countries, children: res.countries};
    });
    next();
  },
  mounted() {
    const thatParentURL = this.parentURL;
    document.getElementById('iframe-agent-portal').setAttribute('src', thatParentURL);
    document.getElementById('iframe-agent-portal').onload = function (){
      const iframe = document.getElementById('iframe-agent-portal');
      iframe.contentWindow.postMessage({ type: 'set-height' , height: document.querySelector('main').offsetHeight},thatParentURL);
    }
    const resize_ob = new ResizeObserver(function(entries) {
      let rect = entries[0].contentRect;
      const iframe = document.getElementById('iframe-agent-portal');
      iframe.contentWindow.postMessage({ type: 'set-height' , height: rect.height},thatParentURL);

    });
    resize_ob.observe(document.querySelector("main"));



  }
}
</script>

<style scoped>

</style>